import { ServiceBase } from "./ServiceBase";
import Result from "../models/Common/Result";
import { AppSettings } from "../utils/settings";
import { ISpaceItem } from "../models/Spaces/Interfaces/ISpaceItem";
import { ISpaceActivity } from "../models/Spaces/Interfaces/ISpaceActivity";

export default class WorkspacesService extends ServiceBase {

    static getSpaces = (): Promise<Result<ISpaceItem[]>> => {

        try {
            var result = WorkspacesService.requestJsonFetch<ISpaceItem[]>({
                method: "GET",
                url: `${AppSettings.CommonSettings.ApiUrl}/group/spaces`
            });
            return result;
        } catch (error) {
        }

        return new Promise<Result<ISpaceItem[]>>(null);

    }

    static getSpacesStatistics = (): Promise<Result<ISpaceActivity[]>> => {

        try {
            var result = WorkspacesService.requestJsonFetch<ISpaceActivity[]>({
                method: "GET",
                url: `${AppSettings.CommonSettings.ApiUrl}/group/spaces-statistics`
            });
            return result;
        } catch (error) {
        }

        return new Promise<Result<ISpaceActivity[]>>(null);

    }

    static getWorkspaceGroup = (): Promise<Result<IWorkspaceGroup>> => {
        try {
          const result = WorkspacesService.requestJsonFetch<IWorkspaceGroup>({
            method: "GET",
            url: `${AppSettings.CommonSettings.ApiUrl}/group`
          })
          return result;
        } catch(err) {}
        return new Promise<Result<IWorkspaceGroup>>(null)
      }

}