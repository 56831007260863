import bind from "bind-decorator";
import Validations from "../../../resources/Common/Validations";
import { String_Format, isEmail, nameof } from "../../../utils/utils";
import { BaseValidator } from "../Base/BaseValidator";
import RoomBookingsGrid from "../../../resources/AdminUI/RoomBookings/RoomBookingsGrid";

export class DeclineRoomBookingConfirmationValidator extends BaseValidator {

    constructor() {
        super();
    }

    @bind
    validate(model: any, props: any) {

        this.check(() => model.reasonId != '' && model.reasonId != null, nameof(e => e.reasonId), String_Format(Validations.Resources.required, RoomBookingsGrid.Resources.reason));

        return this.getErrorsDictionary();
    }
}