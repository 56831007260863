import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { ApplicationState, reducers } from './';

export default function configureStore( initialState?: ApplicationState) {
    const middleware = [
        thunk,
    ];

    const rootReducer = combineReducers({
        ...reducers,
    });

    const enhancers = [];
    if (typeof window !== 'undefined' && window["__REDUX_DEVTOOLS_EXTENSION__"]) {
        enhancers.push(window["__REDUX_DEVTOOLS_EXTENSION__"]());
    }

    return createStore(
        rootReducer,
        initialState,
        compose(applyMiddleware(...middleware), ...enhancers)
    );
}
