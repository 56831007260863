import { IError, IObjectErrors } from "./IError";

export default class Result<T> {
    public value: T;
    public errors: IError;
    public get hasErrors(): boolean {
        return this.errors != undefined && Object.keys(this.errors).length != 0;
    }

    constructor(value: T, objectErrors?: IObjectErrors) {
        this.value = value as T;
        this.errors = objectErrors == undefined || objectErrors == null ? {} : objectErrors.errors;
    }
}