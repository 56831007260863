import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import update from "immutability-helper";
import LookupService from "../services/LookupService";
import { DefaultFilterValues } from "../enums/Common/DefaultFilterValues";
import General from "../resources/AdminUI/General";
import { IAntdSelectListItem } from "../models/Common/IAntdSelectListItem";

export module LookupStore {

    export interface IState {
        groupWorkspaces: IAntdSelectListItem[];
    }

    export enum Actions {
        GetGroupWorkspaces = "LOOKUP_GET_GROUP_WORKSPACES",
    }

    interface IGetGroupWorkspaces {
        type: Actions.GetGroupWorkspaces,
        groupWorkspaces: IAntdSelectListItem[]
    }


    type KnownAction = IGetGroupWorkspaces;

    export const actionCreators = {

        getGetGroupWorkspaces: (addAllOption?: boolean): AppThunkAction<KnownAction> => async (dispatch, getState) => {
                var groupworkspaces = await LookupService.getGroupWorkspaces().then(res => res.value);
                if(addAllOption){
                    groupworkspaces.unshift({
                        label: General.Resources.allWorkspaces,
                        value: DefaultFilterValues.All.toString(),
                        selected: true
                    } as IAntdSelectListItem)
                }
                dispatch({ type: Actions.GetGroupWorkspaces, groupWorkspaces: groupworkspaces });
            },
    }

    const initialState: IState = {
        groupWorkspaces: null
    }

    export const reducer: Reducer<IState> = (currentState: IState, incomingAction: Action) => {
        const action = incomingAction as KnownAction;

        switch (action.type) {
            case Actions.GetGroupWorkspaces:
                return update(currentState,
                    {
                        groupWorkspaces: { $set: action.groupWorkspaces }
                    });
            default:
                return currentState || initialState;
        }


    }

}