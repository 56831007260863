import Globals from "./../../Globals";

export default class General {
       public accept: string = `Accept`;
       public actions: string = `Actions`;
       public activateLabel: string = `ACTIVATE`;
       public active: string = `Active`;
       public allCities: string = `All Cities`;
       public allCountries: string = `All Countries`;
       public areYouSureModalLabel: string = `Are you sure?`;
       public at: string = `at`;
       public cancelButtonLabel: string = `CANCEL`;
       public closed: string = `Closed`;
       public deactivateLabel: string = `DEACTIVATE`;
       public deleteLabel: string = `DELETE`;
       public decline: string = `Decline`;
       public edit: string = `Edit`;
       public englishDefault: string = `ENGLISH (DEFAULT)`;
       public highlighted: string = `Highlighted:`;
       public loading: string = `Loading...`;
       public next: string = `Next`;
       public noElements: string = `No elements`;
       public notActive: string = `Not Active`;
       public notCompletedYet: string = `Not completed yet`;
       public others: string = `Others: `;
       public portuguese: string = `PORTUGUESE`;
       public refresh: string = `Refresh`;
       public save: string = `SAVE`;
       public select: string = `Select...`;
       public spanish: string = `SPANISH`;
       public lastOnLabel: string = `Last on {0}`;
       public nextOnLabel: string = `Next on {0}`;
       public closeLabel: string = `Close`;
       public defaultTeamName: string = `Default Team`;
       public allWorkspaces: string = `All Workspaces`;
       public anyUser: string = `Any user`;
       public moreActions: string = `More actions`;
       public group: string = `group`;
       public overall: string = `Overall`;
       public thisMonth: string = `This month`;
       public every: string = `every`;
       public value: string = `value`;
       public logout: string = `Logout`;

		public static get Resources(): General{
			switch (Globals.currentActiveLanguage) {
				default:
				return new General();
		}
   	}
}
