import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import { ISpaceItem } from "../models/Spaces/Interfaces/ISpaceItem";
import update from "immutability-helper";
import WorkspacesService from "../services/WorkspacesService";
import { ISpaceActivity } from "../models/Spaces/Interfaces/ISpaceActivity";

export module WorkspaceGroupStore {

    export interface IState {
      name: string,
    }

    export enum Actions {
        Initialize = "WORKSPACE_GROUP_INITIALIZE",
        SetData = "WORKSPACE_GROUP_SET_DATA"
    }

    interface IInitialize {
        type: Actions.Initialize;
    }
    interface ISetData {
      type: Actions.SetData,
      workspaceGroup: IWorkspaceGroup
  }

    type KnownAction = IInitialize | ISetData

    export const actionCreators = {

        getData: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            dispatch({ type: Actions.Initialize });

            var workspaceGroup = await WorkspacesService.getWorkspaceGroup().then(res => res.value);

            dispatch({ type: Actions.SetData, workspaceGroup });
        }
    }

    const initialState: IState = {
        name: 'Pluria International'
    }

    export const reducer: Reducer<IState> = (currentState: IState, incomingAction: Action) => {
        const action = incomingAction as KnownAction;

        switch (action.type) {
            case Actions.Initialize:
                return initialState;
            case Actions.SetData:
                return update(currentState,
                    {
                      $set: action.workspaceGroup
                    });
            default:
                return currentState || initialState;
        }


    }

}