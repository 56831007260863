import { ServiceBase } from "./ServiceBase";
import Result from "../models/Common/Result";
import { AppSettings } from "../utils/settings";
import { EntitiesList, IEntitiesList } from "../models/Common/IEntitiesList";
import { IHotdeskBookingsItem } from "../models/HotdeskBookings/Interfaces/IHotdeskBookingsItem";
import { HotdeskBookingsItem } from "../models/HotdeskBookings/HotdeskBookingsItem";

export default class HotdeskBookingsService extends ServiceBase {

    static getHotdeskBookings = (toTake: number, toSkip: number, filters: string): Promise<Result<IEntitiesList<IHotdeskBookingsItem>>> => {

        var url = `/odata/hotdesk-bookings/?$top=${toTake}&$skip=${toSkip}`;

        if (filters && filters.length > 0) {
            url = `${url}&$filter=${filters}`;
        }

        try {
            var result = HotdeskBookingsService.requestJsonFetch<IEntitiesList<IHotdeskBookingsItem>>({
                method: "GET",
                url: `${AppSettings.CommonSettings.ApiUrl}${url}&$orderby=Id desc&$count=true`
            });
            return result;
        } catch (error) {
        }

        return new Promise<Result<IEntitiesList<IHotdeskBookingsItem>>>(null);

    }

    static additionalMapping = (entitiesList: IEntitiesList<IHotdeskBookingsItem>): EntitiesList<IHotdeskBookingsItem> => {
        var newEntities = new EntitiesList<IHotdeskBookingsItem>();
        newEntities.entities = entitiesList.entities.map((e) => new HotdeskBookingsItem(e));
        newEntities.numberOfEntities = entitiesList.numberOfEntities;
        return newEntities;
    }

}