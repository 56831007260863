import React from "react";
import Helmet from 'react-helmet';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ApplicationState } from "../../store";
import { String_Format } from "../../utils/utils";
import { RoomBookingsGridStore } from "../../store/RoomBookingsGridStore";
import RoomBookingsTable from "../../components/RoomBookings/RoomBookingsTable";
import RoomBookingsGrid from "../../resources/AdminUI/RoomBookings/RoomBookingsGrid";
import { RoomBookingStatuses } from "../../enums/RoomBookings/RoomBookingStatuses";

interface IProps {
    filters: string,
    initialize: (defaultSelectedItemsPerPageOption: number) => void,
    reload: () => Promise<any>,
    changeCurrentPage: (currentPage: number) => void,
    setFilters: (filters: string) => void,
}

class PendingRoomRequestsPage extends React.PureComponent<any, any>{
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    statusFilterTemplate = `StatusId eq {0}`;
    singleSelectedStatus = RoomBookingStatuses.Pending.toString();

    render() {
        return <div className="space-list-page">
            <Helmet title={RoomBookingsGrid.Resources.pendingRoomsRequest} />

            <div className="page-top-container">
                <div className="page-title">{RoomBookingsGrid.Resources.pendingRoomsRequest}</div>
            </div>
           
            <RoomBookingsTable
                componentId={componentId}
                hiddenColumns={["statusId", "workspaceName", "startsAt", "unixStartsAtWithTimezone", "endsAt", "cancelledAt", "expiresAt", "rejectedAt", "statusName", "bookingAmount", "userId", "userFirstName", "userLastName", "roomName", "timezone"]}
                expandableColumns={["userId"]}
                filters={String_Format(this.statusFilterTemplate, this.singleSelectedStatus)}
                showPendingActionsColumn={true}
            />

        </div>

    }
}

let componentId = "PendingRoomRequestsGridPage";

export default connect(
    (state: ApplicationState) => {
        return {}
    },
    (dispatch) => bindActionCreators({
        ...RoomBookingsGridStore.getActionCreators(componentId),
    }, dispatch)
)(PendingRoomRequestsPage as any);