import { Modal } from "antd";

export default (props) => {
    return (
        <Modal
            destroyOnClose={true}
            footer={null}
            centered={true}
            width={700}
            {...props}
        />
    );
};