import Globals from "./../../../Globals";

export default class RoomBookingRejectReasonList {
       public other: string = `Other`;
       public roomUnavailable: string = `Room Unavailable`;
       public technicalIssues: string = `Technical Issues`;
       public underMaintenance: string = `Under Maintenance`;

		public static get Resources(): RoomBookingRejectReasonList{
			switch (Globals.currentActiveLanguage) {
				default:
				return new RoomBookingRejectReasonList();
		}
   	}
}
