import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../store/index";
import Nav from "./Nav";
import LoginPage from "../pages/Login/LoginPage";


interface IProps {
    children?: React.ReactNode;
    loginRequest: (path: string) => any;
    loginState: string;
}

interface IState {
}

class Layout extends React.Component<IProps, IState>{
    page: HTMLDivElement;

    constructor(props) {
        super(props);
    }

    public render() {
        return (
            <div className="pluria">
                <div className="page" ref={node => this.page = node}>
                    <div className="sidebar">
                        <Nav />
                    </div>
                    <div className="page-content">
                        <div className="content">
                            {this.props.children}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

class AuthorizedLayout extends Layout {
    constructor(props) {
        super(props);
    }
    public static defaultProps: Partial<IProps> = {
    }

    public render() {
        if (this.props.loginState != 'LOGGED_IN') {
            return <LoginPage />;
        }
        return <Layout {...this.props} />
    }
}


const component = connect(
    (state: ApplicationState) => { return { loginState: state.login.loginState } }
)(AuthorizedLayout);

export var AuthorizedLayoutComponent = component
