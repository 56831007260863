import React from "react";
import { ISpaceActivity } from "../../models/Spaces/Interfaces/ISpaceActivity";
import SpaceMonthActivityCard from "./SpaceMonthActivityCard";
import General from "../../resources/AdminUI/General";


interface IProps {
    statistics?: ISpaceActivity[];
}

interface IState { }

export default class SpaceTotalActivityContainer extends React.PureComponent<IProps, IState>{
    constructor(props) {
        super(props);
    }


    render() {
        return <div className="el-tabs__content">
            <div className="container_entries">
                {this.props.statistics.length != 0 ?
                    this.props.statistics.map((e, index) => {
                        return <SpaceMonthActivityCard item={e} key={e.workspaceId + "-" + index} />
                    })
                    : <div>{General.Resources.noElements}</div>}
            </div>
        </div>
    }
}
