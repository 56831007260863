import bind from "bind-decorator";
import React from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ApplicationState } from "../../store";
import {
  String_Format,
  firstLetterToLowerCase,
  getPromiseFromAction,
  nameof,
} from "../../utils/utils";
import { DefaultFilterValues } from "../../enums/Common/DefaultFilterValues";
import { LookupStore } from "../../store/LookupStore";
import RegularExpressions from "../../resources/Common/RegularExpressions";
import { HotdeskBookingsGridStore } from "../../store/HotdeskBookingsGridStore";
import HotdeskBookingsGridPageTable from "../../components/HotdeskBookings/HotdeskBookingsTable";
import { HotdeskBookingStatuses } from "../../enums/HotdeskBookings/HotdeskBookingStatuses";
import HotdeskBookingStatusTypes from "../../resources/AdminUI/HotdeskBookings/HotdeskBookingStatusTypes";
import { DatePicker, Select } from "antd";
import HotdeskBookingsGrid from "../../resources/AdminUI/HotdeskBookings/HotdeskBookingsGrid";
import { NavigateFunction } from "react-router-dom";
import { IAntdSelectListItem } from "../../models/Common/IAntdSelectListItem";
import dayjs from "dayjs";
import General from "../../resources/AdminUI/General";
import Loading from "../../components/Shared/Loading";
const { RangePicker } = DatePicker;

interface IProps {
  filters: string;
  searchTerm: string;
  singleSelectedStatus: string;
  singleSelectedWorkspace: string;
  startDateSearchTemplate: number;
  endDateSearchTemplate: number;
  workspaces: IAntdSelectListItem[];

  navigate: NavigateFunction;

  initialize: (defaultSelectedItemsPerPageOption: number) => void;
  reload: () => Promise<any>;
  changeCurrentPage: (currentPage: number) => void;
  getGetWorkspaces: (addDefaultOption: boolean) => void;
  setFilters: (filters: string) => void;
  setSearchTerm: (searchTerm: string) => void;
  setSingleSelectedStatus: (singleSelectedStatus: string) => void;
  setSingleSelectedWorkspace: (singleSelectedWorkspace: string) => void;
  setStartDateSearchTemplateAndEndDateSearchTemplate: (
    startDateSearchTemplate: number,
    endDateSearchTemplate: number
  ) => void;
}

class HotdeskBookingsGridPage extends React.PureComponent<any, any> {
  constructor(props) {
    super(props);
    this.state = {};

    this.props.getGetWorkspaces(true);
  }

  nameSearchTemplate = `contains(concat(concat(tolower(UserFirstName), ' '), tolower(UserLastName)), tolower('{0}'))`;
  idSearchTemplate = ` or Id eq {0} or contains(tolower(BookingGroupId), tolower('{0}'))`;
  statusFilterTemplate = `StatusId eq {0}`;
  workspaceFilterTemplate = "WorkspaceId eq {0}";
  startDateFilterTemplate = `UnixBookingAtWithTimezone eq {0}`;
  startAndEndDateFilterTemplate = `UnixBookingAtWithTimezone ge {0} and UnixBookingAtWithTimezone le {1}`;
  dateFormat = "DD-MM-YYYY";

  @bind
  rebuildODataAndGetData() {
    var filters = [];
    if (this.props.searchTerm) {
      var searchFilter = String_Format(
        this.nameSearchTemplate,
        this.props.searchTerm
      );
      var pattern = RegularExpressions.Resources.onlyNumbers;
      if (RegExp(pattern).test(this.props.searchTerm)) {
        searchFilter = searchFilter.concat(
          String_Format(this.idSearchTemplate, this.props.searchTerm)
        );
      }
      filters.push(`(${searchFilter})`);
    }

    this.props.singleSelectedStatus &&
    this.props.singleSelectedStatus != DefaultFilterValues.All.toString()
      ? filters.push(
          `(${String_Format(
            this.statusFilterTemplate,
            this.props.singleSelectedStatus
          )})`
        )
      : null;

    this.props.singleSelectedWorkspace &&
    this.props.singleSelectedWorkspace != DefaultFilterValues.All.toString()
      ? filters.push(
          `(${String_Format(
            this.workspaceFilterTemplate,
            this.props.singleSelectedWorkspace
          )})`
        )
      : null;

    if (
      this.props.startDateSearchTemplate &&
      this.props.endDateSearchTemplate
    ) {
      if (
        this.props.startDateSearchTemplate == this.props.endDateSearchTemplate
      ) {
        var startDatefilter = String_Format(
          this.startDateFilterTemplate,
          this.props.startDateSearchTemplate
        );
        filters.push(`(${startDatefilter})`);
      } else {
        var startAndEndDatefilter = String_Format(
          this.startAndEndDateFilterTemplate,
          this.props.startDateSearchTemplate,
          this.props.endDateSearchTemplate
        );
        filters.push(`(${startAndEndDatefilter})`);
      }
    }

    var oDataFilter = filters.join(" and ");
    this.props.setFilters(oDataFilter);
    this.props.changeCurrentPage(1);
    getPromiseFromAction(this.props.reload()).then(() =>
      this.props.changeCurrentPage(1)
    );
  }

  @bind
  onSearchFilterChange(e: React.FormEvent<HTMLInputElement>) {
    let searchTerm = e.currentTarget.value;
    if (this.props.searchTerm != searchTerm) {
      getPromiseFromAction(this.props.setSearchTerm(searchTerm)).then(() => {
        this.rebuildODataAndGetData();
      });
    }
  }

  @bind
  onStatusFilterChange(value: string, option: any) {
    getPromiseFromAction(this.props.setSingleSelectedStatus(value)).then(() => {
      this.rebuildODataAndGetData();
    });
  }

  @bind
  onWorkspaceFilterChange(value: string, values: any) {
    getPromiseFromAction(this.props.setSingleSelectedWorkspace(value)).then(
      () => {
        this.rebuildODataAndGetData();
      }
    );
  }

  @bind
  onDateChange(dates: [any, any], dateStrings: [string, string]) {
    if (dateStrings[0] == dateStrings[1]) {
      var startDate = dateStrings[0].split("-").reverse().join("-");
      var startDateSearchTemplate = parseInt(
        (new Date(startDate).getTime() / 1000).toFixed(0)
      );
      getPromiseFromAction(
        this.props.setStartDateSearchTemplateAndEndDateSearchTemplate(
          startDateSearchTemplate,
          startDateSearchTemplate
        )
      ).then(() => {
        this.rebuildODataAndGetData();
      });
    } else {
      var startDate = dateStrings[0].split("-").reverse().join("-");
      var endDate = dateStrings[1].split("-").reverse().join("-");
      var startDateSearchTemplate = parseInt(
        (new Date(startDate).getTime() / 1000).toFixed(0)
      );
      var endDateSearchTemplate = parseInt(
        (new Date(endDate).getTime() / 1000).toFixed(0)
      );
      getPromiseFromAction(
        this.props.setStartDateSearchTemplateAndEndDateSearchTemplate(
          startDateSearchTemplate,
          endDateSearchTemplate
        )
      ).then(() => {
        this.rebuildODataAndGetData();
      });
    }
  }

  @bind
  getAllHodeskStatuses() {
    var options = [];
    let keys = Object.keys(HotdeskBookingStatuses).filter(
      (k) => typeof HotdeskBookingStatuses[k as any] === "number"
    );

    keys.forEach((key) => {
      let value = HotdeskBookingStatuses[key as any].toString();
      options.push({
        value: value,
        label: HotdeskBookingStatusTypes.Resources[firstLetterToLowerCase(key)],
      });
    });
    return options;
  }

  render() {
    if (!this.props.workspaces) {
      return (
        <div>
          <Loading />
        </div>
      );
    }
    return (
      <div>
        <Helmet title={HotdeskBookingsGrid.Resources.desksLogs} />

        <div className="page-top-container">
          <div className="page-title">
            {HotdeskBookingsGrid.Resources.desksLogs}
          </div>
        </div>

        <div className="filter-bar">
          <div className="filter-search">
            <div className="input-group">
              <span className="icon material-symbols-outlined">search</span>
              <input
                type="text"
                className="form-control"
                placeholder={
                  HotdeskBookingsGrid.Resources.searchByLogIdOrUserName
                }
                onChange={this.onSearchFilterChange}
                name="searchTerm"
                value={this.props.searchTerm}
              />
            </div>
          </div>

          <div className="filter-item">
            <div className="input-group">
              <Select
                options={this.props.workspaces}
                onChange={this.onWorkspaceFilterChange}
                value={this.props.singleSelectedWorkspace}
                showSearch
                optionFilterProp="label"
              />
            </div>
          </div>

          <div className="filter-item">
            <div className="input-group">
              <Select
                options={this.getAllHodeskStatuses()}
                onChange={this.onStatusFilterChange}
                value={this.props.singleSelectedStatus}
              ></Select>
            </div>
          </div>

          <RangePicker
            value={[
              this.props.startDateSearchTemplate
                ? dayjs.unix(this.props.startDateSearchTemplate)
                : this.props.startDateSearchTemplate,
              this.props.endDateSearchTemplate
                ? dayjs.unix(this.props.endDateSearchTemplate)
                : this.props.endDateSearchTemplate,
            ]}
            format={this.dateFormat}
            onChange={this.onDateChange}
          />
        </div>

        <HotdeskBookingsGridPageTable
          hiddenColumns={[
            "createdAt",
            "unixBookingAtWithTimezone",
            "bookingAt",
            "checkinAt",
            "checkoutAt",
            "cancelledAt",
            "expiresAt",
            "statusName",
            "timeSpent",
            "timezone",
            "isFullDay",
            "isAutomatedCheckout",
            "userFirstName",
            "userLastName",
            "workspaceName",
          ]}
          expandableColumns={["createdAt"]}
          filters={this.props.filters}
          showActionsColumn={true}
          componentId={componentId}
        />
      </div>
    );
  }
}

const componentId = "HotdeskBookingGridPage";

export default connect(
  (state: ApplicationState) => {
    const componetState = state.hotdeskBookingsGrid[componentId];
    return {
      filters: componetState?.filters || "",
      searchTerm: componetState?.searchTerm || "",
      singleSelectedStatus:
        componetState?.singleSelectedStatus ||
        DefaultFilterValues.All.toString(),
      singleSelectedWorkspace:
        componetState?.singleSelectedWorkspace ||
        DefaultFilterValues.All.toString(),
      startDateSearchTemplate: componetState?.startDateSearchTemplate || null,
      endDateSearchTemplate: componetState?.endDateSearchTemplate || null,
      workspaces: state.lookup.groupWorkspaces,
    };
  },
  (dispatch) =>
    bindActionCreators(
      {
        ...HotdeskBookingsGridStore.getActionCreators(componentId),
        getGetWorkspaces: LookupStore.actionCreators.getGetGroupWorkspaces,
      },
      dispatch
    )
)(HotdeskBookingsGridPage);
