
export class BaseValidator {

    errors: {};

    constructor() {
        this.errors = {}
    }

    check(checkProperty, key: string, value: string) {
        if(!checkProperty()){
            if (!this.errors[key] || !(this.errors[key] instanceof Array)) {
                this.errors[key] = [];
            }
        
            this.errors[key].push(value);
        }
        
    }

    getErrorsDictionary(){
        return this.errors;
    }
}