import { LoginStore } from "./LoginStore";
import { TabStore } from "./TabStore";
import { NotificationStore } from "./NotificationStore";
import { LookupStore } from "./LookupStore";
import { HotdeskBookingsGridStore } from "./HotdeskBookingsGridStore";
import { RoomBookingsGridStore } from "./RoomBookingsGridStore";
import { WorkspacesStore } from "./WorkspacesStore";
import { WorkspaceGroupStore } from "./WorkspaceGroupStore";

export type StateDictionary<T> = { [key: string]: T };

// The top-level state object
export interface ApplicationState {
    login: LoginStore.IState;
    notification: NotificationStore.IState;
    lookup: LookupStore.IState;
    tab: StateDictionary<TabStore.IState>;
    hotdeskBookingsGrid: StateDictionary<HotdeskBookingsGridStore.IState>;
    roomBookingsGrid: StateDictionary<RoomBookingsGridStore.IState>;
    dashboard: WorkspacesStore.IState;
    workspaceGroup: WorkspaceGroupStore.IState;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    login: LoginStore.reducer,
    tab: TabStore.reducer,
    notification: NotificationStore.reducer,
    lookup: LookupStore.reducer,
    hotdeskBookingsGrid: HotdeskBookingsGridStore.reducer,
    roomBookingsGrid: RoomBookingsGridStore.reducer,
    dashboard: WorkspacesStore.reducer,
    workspaceGroup: WorkspaceGroupStore.reducer
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}

export interface AppThunkActionAsync<TAction, TResult> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): Promise<TResult>
}

export interface AppThunkArrayAction<TAction> {
    (dispatch: (action: TAction, key: string) => void, getState: () => ApplicationState): void;
}

export interface AppThunkArrayActionAsync<TAction, TResult> {
    (dispatch: (action: TAction, key: string) => void, getState: () => ApplicationState): Promise<TResult>
}

// create methods with the key as parameter

export function initAllStores(dispatch, getState) {
    LoginStore.actionCreators.init()(dispatch, getState);
    LoginStore.initBoundActions(dispatch, getState);
}