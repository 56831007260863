import Globals from "./../../Globals";

export default class RegularExpressions {
       public emailValidation: string = `[@]`;
       public onlyNonWhitespaces: string = `^\\S*$`;
       public onlyNumbers: string = `^[0-9]+$`;

		public static get Resources(): RegularExpressions{
			switch (Globals.currentActiveLanguage) {
				default:
				return new RegularExpressions();
		}
   	}
}
