import Globals from "./../../../Globals";

export default class RoomBookingStatusTypes {
  public allStatuses: string = `Any status`;
  public cancelled: string = `Cancelled`;
  public confirmed: string = `Confirmed`;
  public ended: string = `Ended`;
  public expired: string = `Unreedemed`;
  public pending: string = `Pending`;
  public rejected: string = `Rejected`;

  public static get Resources(): RoomBookingStatusTypes {
    switch (Globals.currentActiveLanguage) {
      default:
        return new RoomBookingStatusTypes();
    }
  }
}
