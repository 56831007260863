import Globals from "./../../../Globals";

export default class RoomBookingsGrid {
       public accept: string = `Accept`;
       public acceptRoomRequest: string = `Accept room request`;
       public acceptRoomRequestConfirmationMessage: string = `Are you sure you want to accept this room request?`;
       public addRoomLog: string = `Add room log`;
       public bookingAmount: string = `{0} - {1} days`;
       public bookingInfo: string = `Booking info`;
       public cancelledAt: string = `Cancelled at: `;
       public companyDashboardExportRoomBookingLogsHeader: string = `Id,Starts At,Created At,Confirmed At,Ends At,Cancelled At,Expires At,Rejected At,Status Name,Booking Type Name,Booking Amount,Price,Currency,User Id,User First Name,User Last Name,User Email,User Phone,Room Name,Workspace Name,Workspace City Name,Workspace Category Name`;
       public csvExtension: string = `.csv`;
       public customer: string = `Customer:`;
       public customerId: string = `Customer ID: #{0}`;
       public decline: string = `Decline`;
       public declineRoomRequest: string = `Decline room request`;
       public declineRoomRequestConfirmationMessage: string = `Are you sure you want to decline this room request?`;
       public emailAddress: string = `Email address:`;
       public expiredAt: string = `Expired at: `;
       public exportRoomBookingLogsHeader: string = `Id,Starts At,Created At,Ends At,Cancelled At,Expires At,Rejected At,Status Id,Status Name,Booking Type Id,Booking Amount,Price,Currency,User Id,User First Name,User Last Name,User Email,User Phone,User Customer Id,User Customer Name,Room Id,Room Name,Workspace Id,Workspace Name,Workspace City Id,Workspace City Name,Workspace Country Id,Workspace Country Name`;
       public exportRoomsLogsCsv: string = `Export Rooms Logs.csv`;
       public fullName: string = `Full name:`;
       public id: string = `ID: #{0}`;
       public pendingRoomsRequest: string = `Pending Rooms Requests`;
       public phoneNumber: string = `Phone number: `;
       public price: string = `Price`;
       public reason: string = `Reason`;
       public reasons: string = `Reasons: `;
       public rejectedAt: string = `Rejected at:`;
       public requestTimestamp: string = `Request timestamp`;
       public room: string = `Room`;
       public roomId: string = `Room ID: #{0}`;
       public roomLogs: string = `Room Logs`;
       public roomsLogs: string = `Rooms Logs`;
       public searchByLogIdOrUserName: string = `Search by log ID or user name `;
       public searchByLogIdUserNameOrCustomerName: string = `Search by log ID, user name, space name or customer name`;
       public searchByLogIdUserNameOrSpaceName: string = `Search by log ID, user name or space name`;
       public space: string = `Space`;
       public spaceId: string = `Space ID: #{0}`;
       public status: string = `Status`;
       public user: string = `User`;
       public userId: string = `User ID: #{0}`;
       public userInfo: string = `User info`;
       public visitId: string = `Visit ID`;

		public static get Resources(): RoomBookingsGrid{
			switch (Globals.currentActiveLanguage) {
				default:
				return new RoomBookingsGrid();
		}
   	}
}
