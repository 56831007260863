import * as React from 'react';
import { Routes, Route } from 'react-router';
import { Navigate } from 'react-router-dom';
import { AuthorizedLayoutComponent } from './layouts/Layout';
import { ApplicationPaths } from './enums/Common/ApplicationPaths';
import NotFoundPage from './pages/NotFoundPage';
import HotdeskBookingsGridPage from './pages/HotdeskBookings/HotdeskBookingsGridPage';
import RoomBookingsGridPage from './pages/RoomBookings/RoomBookingsGridPage';
import PendingRoomRequestsPage from './pages/RoomBookings/PendingRoomRequestsPage';
import DashboardPage from './pages/Dashboard/DashboardPage';
import UnauthorizedPage from './pages/UnauthorizedPage';

export default class RouteList extends React.Component<any, any> {

    public render() {
        return (
            <Routes>
                <Route path={ApplicationPaths.EmptyPath} element={<Navigate to={ApplicationPaths.Dashboard} replace/>} />;
                <Route path={ApplicationPaths.Unauthorized} element={<UnauthorizedPage />} />

                <Route path={ApplicationPaths.Dashboard} element={<AuthorizedLayoutComponent><DashboardPage /></AuthorizedLayoutComponent>} />

                <Route path={ApplicationPaths.DesksLogs} element={<AuthorizedLayoutComponent><HotdeskBookingsGridPage /></AuthorizedLayoutComponent>} />
                
                <Route path={ApplicationPaths.RoomsLogs} element={<AuthorizedLayoutComponent><RoomBookingsGridPage /></AuthorizedLayoutComponent>} />

                <Route path={ApplicationPaths.PendingRoomRequests} element={<AuthorizedLayoutComponent><PendingRoomRequestsPage /></AuthorizedLayoutComponent>} />

                <Route path={ApplicationPaths.NotFound} element={<AuthorizedLayoutComponent><NotFoundPage /></AuthorizedLayoutComponent>} />
                <Route path="*" element={<AuthorizedLayoutComponent><NotFoundPage /></AuthorizedLayoutComponent>} />
            </Routes>);
    }
}