import { String_Format } from "../../utils/utils";
import bind from "bind-decorator";
import { v4 } from "uuid";
import * as React from "react";
import IPageIndex from "../../models/Pagination/IPageIndex";


interface IProps {
    gridTextItemResource?: any;
    totalItems: number;

    itemsPerPageOptions?: number[];
    useItemsPerPageOptions?: boolean;
    itemsPerPage?: number;
    maxPagesDisplayed?: number;
    pageNeighbours?: number;
    hasRefreshButton?: boolean;

    reload: () => void;
    onRefreshClick: () => void;
    changeCurrentPage: (currentPage: number) => void;
    changePageSize?: (pageSize: number) => void;

    firstIndexFromPage: number;
    lastIndexFromPage: number;
    pageIndexArray: IPageIndex[];

}

export default class PaginationBar extends React.PureComponent<IProps, {}>{

    public static defaultProps: Partial<IProps> = {
        maxPagesDisplayed: 7,
        pageNeighbours: 1,
        itemsPerPage: 25,
        itemsPerPageOptions: [5, 10, 15, 20, 25],
        useItemsPerPageOptions: true
    }

    constructor(props) {
        super(props);
    }

    @bind
    calculatePageIndexArrayRendered(pageIndexArray: IPageIndex[]) {
        var array = [];
        var totalPages = pageIndexArray.length;

        if (pageIndexArray.length) {
            var currentPage = pageIndexArray.filter(p => p.isActive)[0].pageNumber;
            var elipsis = <a onClick={(e) => { e.preventDefault() }} className='pagination-elipsis' href="#">...</a>;

            var leftSizeEllipsis = currentPage - this.props.pageNeighbours - pageIndexArray[0].pageNumber - 1;
            var rightSizeEllipsis = totalPages - currentPage - this.props.pageNeighbours - pageIndexArray[0].pageNumber;

            if (leftSizeEllipsis <= 0) {
                rightSizeEllipsis += (leftSizeEllipsis - 1);
            }
            if (rightSizeEllipsis <= 0) {
                leftSizeEllipsis += (rightSizeEllipsis - 1);
            }

            array.push(this.renderPageIndex(pageIndexArray[0]));
            if (leftSizeEllipsis >= 1) {
                array.push(<li key={v4()}>{elipsis}</li>);
            }

            pageIndexArray.forEach(i => {
                if (totalPages <= this.props.maxPagesDisplayed && i.pageNumber != 1 && i.pageNumber != totalPages) {
                    array.push(this.renderPageIndex(i));
                }
                else
                    if (i.pageNumber != 1
                        && i.pageNumber != totalPages
                        && i.pageNumber != pageIndexArray[totalPages - 1].pageNumber
                        && i.pageNumber > 1 + leftSizeEllipsis
                        && i.pageNumber <= totalPages - rightSizeEllipsis - 1) {
                        array.push(this.renderPageIndex(i));
                    }

            });
            if (rightSizeEllipsis >= 1) {
                array.push(<li key={v4()}>{elipsis}</li>);
            }
            if (totalPages != 1) {
                array.push(this.renderPageIndex(pageIndexArray[totalPages - 1]));
            }
        }

        return array;
    }


    @bind
    onItemPerPageChange(e: React.FormEvent<HTMLSelectElement>) {
        e.preventDefault();
        var targetValue = +e.currentTarget.value;
        this.props.changePageSize(targetValue);

        this.props.reload();
    }

    @bind
    onPrevButtonChange(e: React.FormEvent<HTMLLIElement>) {
        e.preventDefault();

        if (!e.currentTarget.classList.contains('disabled')) {
            var currentPage = this.props.pageIndexArray.filter(p => p.isActive)[0].pageNumber;
            this.props.changeCurrentPage(currentPage - 1);

            this.props.reload();
        }
    }

    @bind
    onNextButtonChange(e: React.FormEvent<HTMLLIElement>) {
        e.preventDefault();
        if (!e.currentTarget.classList.contains('disabled')) {
            var currentPage = this.props.pageIndexArray.filter(p => p.isActive)[0].pageNumber;

            this.props.changeCurrentPage(currentPage + 1);

            this.props.reload();
        }
    }

    @bind
    onPageIndexClick(e: React.FormEvent<HTMLLIElement>) {
        e.preventDefault();
        var pageIndexClicked = +e.currentTarget.attributes.getNamedItem('data-page-number').value;
        if (this.props.pageIndexArray[pageIndexClicked - 1].isActive == false) {

            this.props.changeCurrentPage(pageIndexClicked);

            this.props.reload();
        }
    }

    @bind
    onRefreshClick(e: React.FormEvent<HTMLSpanElement>) {
        this.props.onRefreshClick();
    }

    @bind
    renderPageIndex(model: IPageIndex) {
        if (model.isActive) {
            return (
                <li key={v4()} className='active' data-page-number={model.pageNumber} onClick={this.onPageIndexClick}>
                    <span>
                        {model.pageNumber}
                    </span>
                </li>);
        } else {
            return (
                <li key={v4()} data-page-number={model.pageNumber} onClick={this.onPageIndexClick}>
                    <a href="#">
                        {model.pageNumber}
                    </a>
                </li>);
        }

    }

    render() {
        var currentPage: number;
        var totalPages: number;
        if (this.props.pageIndexArray && this.props.pageIndexArray.length) {
            currentPage = this.props.pageIndexArray.filter(p => p.isActive)[0].pageNumber;
            totalPages = this.props.pageIndexArray.length;
            return (
                <div className="pagination">

                    <div className="items-info">
                            {
                            this.props.gridTextItemResource!= null ?
                            String_Format(this.props.gridTextItemResource, this.props.totalItems == 0 ? 0 : this.props.firstIndexFromPage, this.props.lastIndexFromPage, this.props.totalItems)
                            : String_Format("Showing {0} - {1} out of {2} items", this.props.totalItems == 0 ? 0 : this.props.firstIndexFromPage, this.props.lastIndexFromPage, this.props.totalItems)
                            }
                    </div>

                    <div>
                        <ul className="pager">
                            <li onClick={this.onPrevButtonChange} className={`prev ${currentPage == 1 || this.props.totalItems == 0 ? 'disabled' : ''}`}>
                                <a href="#"><span className="material-symbols-outlined">chevron_left</span></a>
                            </li>

                            {this.calculatePageIndexArrayRendered(this.props.pageIndexArray).map(i => i)}
                            <li onClick={this.onNextButtonChange} className={`next ${currentPage == totalPages || this.props.totalItems == 0 ? 'disabled' : ''}`}>
                                <a href="#"><span className="material-symbols-outlined">chevron_right</span></a>
                            </li>
                        </ul>
                    </div>
                </div>

            );
        }
        return null;
    }
}
