import { Action, Reducer } from "redux";
import { AppThunkAction, StateDictionary } from ".";
import update from "immutability-helper";
import IPageIndex from "../models/Pagination/IPageIndex";
import { IRoomBookingsItem } from "../models/RoomBookings/Interfaces/IRoomBookingsItem";
import RoomBookingsService from "../services/RoomBookingsService";
import { DefaultFilterValues } from "../enums/Common/DefaultFilterValues";

export module RoomBookingsGridStore {

    export interface IState {
        loading?: boolean;
        itemsPerPage?: number;
        currentPage?: number;
        firstIndexFromPage?: number;
        lastIndexFromPage?: number;
        pageIndexArray?: IPageIndex[];
        filters?: string;

        roomBookings?: IRoomBookingsItem[];
        numberOfRoomBookings?: number;
        isRoomBookingHiddenList?: boolean[];

        endDateSearchTemplate?: number;
        startDateSearchTemplate?: number;
        singleSelectedStatus?: string;
        searchTerm?: string;
        singleSelectedWorkspace?: string;


    }

    const initialState: IState = {
        currentPage: 1,
        firstIndexFromPage: 1,
        lastIndexFromPage: 1,
        pageIndexArray: [],
    }

    export enum Actions {
        ToggleLoading = "ROOM_BOOKINGS_GRID_TOGGLE_LOADING",
        Initialize = "ROOM_BOOKINGS_GRID_INITIALIZE",
        InitializeHiddenList = "ROOM_BOOKINGS_GRID_INITIALIZE_HIDDEN_LIST",
        ReloadData = "ROOM_BOOKINGS_GRID_RELOAD_DATA",
        RecalculatePageArray = "ROOM_BOOKINGS_GRID_RECALCULATE_PAGE_ARRAY",
        RecalculateIndexes = "ROOM_BOOKINGS_GRID_RECALCULATE_INDEXES",
        ChangeCurrentPage = "ROOM_BOOKINGS_GRID_CHANGE_CURRENT_PAGE",
        SetFilters = "ROOM_BOOKINGS_GRID_SET_FILTERS",

        ChangeSearchTerm = "ROOM_BOOKINGS_GRID_CHANGE_SEARCH_TERM",
        ChangeSingleSelectedStatus = "ROOM_BOOKINGS_GRID_CHANGE_SINGLE_SELECTED_STATUS",
        ChangeStartDateSearchTemplateAndEndDateSearchTemplate = "ROOM_BOOKINGS_GRID_CHANGE_START_DATE_SEARCH_TEMPLATE_AND_END_DATE_SEARCH_TEMPLATE",
        ChangeSingleSelectedWorkspace = "ROOM_BOOKINGS_GRID_CHANGE_SINGLE_SELECTED_WORKSPACE",

    }

    interface IInitialize {
        componentId: string,
        type: Actions.Initialize,
        itemsPerPage: number,
    }

    interface IInitializeHiddenList {
        componentId: string,
        type: Actions.InitializeHiddenList,
    }

    interface IToggleLoading {
        componentId: string,
        type: Actions.ToggleLoading,
        loadingValue: boolean,
    }

    interface IReloadData {
        componentId: string,
        type: Actions.ReloadData,
        roomBookings: IRoomBookingsItem[],
        numberOfRoomBookings: number,
    }

    interface IRecalculatePageArray {
        componentId: string,
        type: Actions.RecalculatePageArray,
    }

    interface IRecalculateIndexes {
        componentId: string,
        type: Actions.RecalculateIndexes,
    }

    interface IChangeCurrentPage {
        componentId: string,
        type: Actions.ChangeCurrentPage,
        currentPage: number,
    }

    interface ISetFilters {
        componentId: string,
        type: Actions.SetFilters,
        filters: string,
    }

    interface IChangeSearchTerm {
        componentId: string,
        type: Actions.ChangeSearchTerm,
        filterValue: string,
    }

    interface IChangeSingleSelectedStatus {
        componentId: string,
        type: Actions.ChangeSingleSelectedStatus,
        filterValue: string,
    }

    interface IChangeStartDateSearchTemplateAndEndDateSearchTemplate {
        componentId: string,
        type: Actions.ChangeStartDateSearchTemplateAndEndDateSearchTemplate,
        startFilterValue: number,
        endFilterValue: number,
    }

    interface IChangeSingleSelectedWorkspace {
        componentId: string,
        type: Actions.ChangeSingleSelectedWorkspace,
        filterValue: string,
    }

    type KnownAction = IToggleLoading |
        IInitialize | IInitializeHiddenList | IReloadData | IRecalculatePageArray | IRecalculateIndexes
        | IChangeCurrentPage | ISetFilters | IChangeSearchTerm | IChangeSingleSelectedStatus 
        | IChangeStartDateSearchTemplateAndEndDateSearchTemplate | IChangeSingleSelectedWorkspace;

    export const getActionCreators = (componentId: string) => ({
        initialize: (defaultSelectedItemsPerPageOption: number, filters: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            var state: IState = getState().roomBookingsGrid[componentId];

            var searchTerm = state?.searchTerm || "";
            var singleSelectedStatus = state?.singleSelectedStatus || DefaultFilterValues.All.toString();
            var startDateSearchTemplate = state?.startDateSearchTemplate || null;
            var endDateSearchTemplate = state?.endDateSearchTemplate || null;
            var singleSelectedWorkspace = state?.singleSelectedWorkspace || DefaultFilterValues.All.toString();

            dispatch({ componentId, type: Actions.Initialize, itemsPerPage: defaultSelectedItemsPerPageOption });
            dispatch({ componentId, type: Actions.ToggleLoading, loadingValue: true });

            var roomBookings = await RoomBookingsService.getRoomBookings(defaultSelectedItemsPerPageOption, 0, filters).then(res => res.value);
            var roomBookingsMapped = RoomBookingsService.additionalMapping(roomBookings);

            if (roomBookingsMapped.entities.length != 0) {
                dispatch({ componentId, type: Actions.ReloadData, roomBookings: roomBookingsMapped.entities, numberOfRoomBookings: roomBookingsMapped.numberOfEntities });
            } else {
                dispatch({ componentId, type: Actions.ReloadData, roomBookings: [], numberOfRoomBookings: 0 });
            }

            dispatch({ componentId, type: Actions.SetFilters, filters: filters });
            dispatch({ componentId, type: Actions.ChangeSearchTerm, filterValue: searchTerm });
            dispatch({ componentId, type: Actions.ChangeSingleSelectedStatus, filterValue: singleSelectedStatus });
            dispatch({ componentId, type: Actions.ChangeSingleSelectedWorkspace, filterValue: singleSelectedWorkspace });
            dispatch({
                type: Actions.ChangeStartDateSearchTemplateAndEndDateSearchTemplate,
                startFilterValue: startDateSearchTemplate,
                endFilterValue: endDateSearchTemplate,
                componentId: componentId
            });
            dispatch({ componentId, type: Actions.InitializeHiddenList });
            dispatch({ componentId, type: Actions.ChangeCurrentPage, currentPage: 1 });
            dispatch({ componentId, type: Actions.RecalculatePageArray });
            dispatch({ componentId, type: Actions.RecalculateIndexes });
            dispatch({ componentId, type: Actions.ToggleLoading, loadingValue: false });
        },
        reload: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            const state = getState().roomBookingsGrid[componentId];

            dispatch({ componentId, type: Actions.ToggleLoading, loadingValue: true });
            var take = state.itemsPerPage;
            var skip = (state.currentPage - 1) * state.itemsPerPage;
            var filters = state.filters;

            var roomBookings = await RoomBookingsService.getRoomBookings(take, skip, filters).then(res => res.value);
            var roomBookingsMapped = RoomBookingsService.additionalMapping(roomBookings);

            if (roomBookingsMapped.entities.length != 0) {
                dispatch({ componentId, type: Actions.ReloadData, roomBookings: roomBookingsMapped.entities, numberOfRoomBookings: roomBookingsMapped.numberOfEntities });
            } else {
                dispatch({ componentId, type: Actions.ReloadData, roomBookings: [], numberOfRoomBookings: 0 });
            }
            
            dispatch({ componentId, type: Actions.InitializeHiddenList });
            dispatch({ componentId, type: Actions.ToggleLoading, loadingValue: false });
        },
        changeCurrentPage: (currentPage: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            dispatch({ componentId, type: Actions.ChangeCurrentPage, currentPage });
            dispatch({ componentId, type: Actions.RecalculatePageArray });
            dispatch({ componentId, type: Actions.RecalculateIndexes });
        },
        setFilters: (filters: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            dispatch({ componentId, type: Actions.SetFilters, filters: filters });
        },
        setSearchTerm: (searchTerm: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            dispatch({ componentId, type: Actions.ChangeSearchTerm, filterValue: searchTerm });
        },
        setSingleSelectedStatus: (singleSelectedStatus: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            dispatch({ componentId, type: Actions.ChangeSingleSelectedStatus, filterValue: singleSelectedStatus });
        },
        setStartDateSearchTemplateAndEndDateSearchTemplate: (startDateSearchTemplate: number, endDateSearchTemplate: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            dispatch({
                type: Actions.ChangeStartDateSearchTemplateAndEndDateSearchTemplate,
                startFilterValue: startDateSearchTemplate,
                endFilterValue: endDateSearchTemplate,
                componentId: componentId
            });
        },
        setSingleSelectedWorkspace: (singleSelectedWorkspace: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            dispatch({ componentId, type: Actions.ChangeSingleSelectedWorkspace, filterValue: singleSelectedWorkspace });
        },
    });

    export const reducer: Reducer<StateDictionary<IState>> = (currentState: StateDictionary<IState>, incomingAction: Action) => {
        const action = incomingAction as KnownAction;

        const componentId = action.componentId;
        const componentState = currentState?.[componentId];

        switch (action.type) {
            case Actions.Initialize:
                return {
                    ...currentState,
                    [componentId]: {
                        ...initialState,
                        itemsPerPage: action.itemsPerPage,
                    }
                };
            case Actions.InitializeHiddenList:
                var hiddenList = new Array(componentState.itemsPerPage).fill(undefined).map((e) => true);
                return update(currentState, {
                    [componentId]:
                    {
                        isRoomBookingHiddenList: { $set: hiddenList }
                    }
                });
            case Actions.ToggleLoading:
                return update(currentState, {
                    [componentId]:
                    {
                        loading: { $set: action.loadingValue }
                    }
                });
            case Actions.ReloadData:
                return update(currentState, {
                    [componentId]:
                    {
                        roomBookings: { $set: action.roomBookings },
                        numberOfRoomBookings: { $set: action.numberOfRoomBookings }
                    }
                });
            case Actions.ChangeCurrentPage:
                return update(currentState, {
                    [componentId]: {
                        currentPage: { $set: action.currentPage }
                    }
                });
            case Actions.RecalculatePageArray:
                var totalPages = Math.floor((componentState.numberOfRoomBookings - 1) / componentState.itemsPerPage) + 1;
                var pageArray = [];
                pageArray.push({ pageNumber: 1, isActive: componentState.currentPage === 1 } as IPageIndex);
                for (let i = 2; i <= totalPages; i++) {
                    pageArray.push({ pageNumber: i, isActive: componentState.currentPage === i });
                }
                return update(currentState, {
                    [componentId]:
                    {
                        pageIndexArray: { $set: pageArray }
                    }
                });
            case Actions.RecalculateIndexes:
                var firstIndex = (componentState.currentPage - 1) * componentState.itemsPerPage + 1;
                var totalPages = componentState.pageIndexArray.length;
                var lastIndex: number;

                if (totalPages == componentState.currentPage)
                    lastIndex = Math.min(componentState.numberOfRoomBookings, componentState.itemsPerPage * totalPages);
                else
                    lastIndex = componentState.currentPage * componentState.itemsPerPage;
                return update(currentState, {
                    [componentId]:
                    {
                        firstIndexFromPage: { $set: firstIndex },
                        lastIndexFromPage: { $set: lastIndex },
                    }
                });
            case Actions.SetFilters:
                return update(currentState, {
                    [componentId]: {
                        filters: { $set: action.filters }
                    }
                });
            case Actions.ChangeSearchTerm:
                return update(currentState, {
                    [componentId]: {
                        searchTerm: { $set: action.filterValue }
                    }
                });
            case Actions.ChangeSingleSelectedStatus:
                return update(currentState, {
                    [componentId]:
                    {
                        singleSelectedStatus: { $set: action.filterValue }
                    }
                });
            case Actions.ChangeStartDateSearchTemplateAndEndDateSearchTemplate:
                return update(currentState, {
                    [componentId]:
                    {
                        startDateSearchTemplate: { $set: action.startFilterValue },
                        endDateSearchTemplate: { $set: action.endFilterValue }
                    }
                });
            case Actions.ChangeSingleSelectedWorkspace:
                return update(currentState, {
                    [componentId]:
                    {
                        singleSelectedWorkspace: { $set: action.filterValue }
                    }
                });
            default:
                return currentState || {};
        }
    }
}