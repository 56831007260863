export enum ApplicationPaths {
    Unauthorized = "/unauthorized",
    EmptyPath = "/",

    Dashboard = "/dashboard",

    DesksLogs = "/desks-logs",

    RoomsLogs = "/rooms-logs",

    PendingRoomRequests = "/pending-room-requests",

    NotFound = "/not-found",
}

export class ApplicationPathBuilder {

    static WorkspaceDashboard = (id: string): string => {
        return `${ApplicationPaths.Dashboard}?id=${id}`;
    }

}
