import React from "react";
import Workspaces from "../../resources/AdminUI/Spaces/Workspaces";
import { String_Format } from "../../utils/utils";
import { AppSettings } from "../../utils/settings";


interface IProps {
    name: string;
    categoryName: string;
    address: string;
    countryName: string;
    cityName: string;
    maxSeats: number;
    meetingRooms: string;
    privateOffices: string;
    image: string;
}

interface IState { }

export default class SpaceDetailsCard extends React.PureComponent<IProps, IState>{
    constructor(props) {
        super(props);
    }


    render() {
         return <div className="space_cards_container">{
            <div className="card_container">    
                <div className="top_info">
                    <div className="photo">
                        <img src={AppSettings.CommonSettings.ImageBaseUrl + this.props.image} alt="room-photo" />
                    </div>
                    <div className="type_name">
                        <div className="space_type">{this.props.categoryName}</div>
                        <div className="space_name">{this.props.name}</div> 
                        <div className="header">Address</div>
                        <div className="info_line bold">{this.props.countryName}, {this.props.cityName}</div>
                        <div className="info_line">{this.props.address}</div>
                    </div>
                </div>
                <div className="header">{Workspaces.Resources.desksBooking}</div>
                {
                    this.props.maxSeats == 0 ?
                        <div>{Workspaces.Resources.spaceDoesNotOfferHotDesks}</div> :
                        !this.props.maxSeats ?
                            <div>{Workspaces.Resources.spaceDoesNotHaveLimitForHotdesks}</div> :
                            <div>{this.props.maxSeats ? String_Format(Workspaces.Resources.spacesRequiresBookingLimitDesksPerDay, this.props.maxSeats) : null}</div>
                }

                <div className="header">{Workspaces.Resources.meetingRooms}</div>
                <div>{this.props.meetingRooms || " - "}</div>
                <div className="header">{Workspaces.Resources.privateOffices}</div>
                <div>{this.props.privateOffices || " - "}</div>

            </div>
         }
            
        </div>
    }
}

