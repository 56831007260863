import { ServiceBase } from "./ServiceBase";
import Result from "../models/Common/Result";
import { AppSettings } from "../utils/settings";
import { EntitiesList, IEntitiesList } from "../models/Common/IEntitiesList";
import { IRoomBookingsItem } from "../models/RoomBookings/Interfaces/IRoomBookingsItem";
import { PayloadTypes } from "../enums/Common/PayloadTypes";
import { RoomBookingsItem } from "../models/RoomBookings/RoomBookingsItem";

export default class RoomBookingsService extends ServiceBase {

    static getRoomBookings = (toTake: number, toSkip: number, filters: string): Promise<Result<IEntitiesList<IRoomBookingsItem>>> => {
        
        var url = `/odata/room-bookings/?$top=${toTake}&$skip=${toSkip}`;
        
        if (filters && filters.length > 0) {
            url = `${url}&$filter=${filters}`;
        }
        
        try{
            var result = RoomBookingsService.requestJsonFetch<IEntitiesList<IRoomBookingsItem>>({
                method: "GET",
                url: `${AppSettings.CommonSettings.ApiUrl}${url}&$orderby=Id desc&$count=true`
            });
            return result;
        }catch (error) {  
        }

        return new Promise<Result<IEntitiesList<IRoomBookingsItem>>>(null);
        
    }
    
    static additionalMapping = (entitiesList: IEntitiesList<IRoomBookingsItem>): EntitiesList<IRoomBookingsItem> => {
        var newEntities = new EntitiesList<IRoomBookingsItem>();
        newEntities.entities = entitiesList.entities.map((e) => new RoomBookingsItem(e));
        newEntities.numberOfEntities = entitiesList.numberOfEntities;
        return newEntities;
    }

    static acceptRoomBooking = (id:number):  Promise<Result<any>> => {
        
        var result = RoomBookingsService.sendData<any>({
            method: "PUT",
            url: `${AppSettings.CommonSettings.ApiUrl}/room-bookings/${id}/accept`,
            data: null
        }, PayloadTypes.Json);

        return result;
        
    }

    static rejectRoomBooking = (id:number, reasonId: number):  Promise<Result<any>> => {
        
        var result = RoomBookingsService.sendData<any>({
            method: "PUT",
            url: `${AppSettings.CommonSettings.ApiUrl}/room-bookings/${id}/reject/${reasonId}`,
            data: null
        }, PayloadTypes.Json);

        return result;
        
    }
    
    static getNumberOfPendingRoomRequests = (): Promise<Result<number>> => {

        try {
            var result = RoomBookingsService.requestJsonFetch<number>({
                method: "GET",
                url: `${AppSettings.CommonSettings.ApiUrl}/room-bookings/pending/count`
            });
            return result;
        } catch (error) {
        }

        return new Promise<Result<number>>(null);

    }


}