import Globals from "./../../Globals";

export default class Validations {
       public atLeastOneImageIsRequired: string = `At least 1 image is required`;
       public domain: string = `Domain is already in use`;
       public emailNotValid: string = `Email must be valid`;
       public fileIsTooLarge: string = `{0} is too large`;
       public fixedLength: string = `The length must be {0}`;
       public generalRequired: string = `Is reqiured!`;
       public latitude: string = `The latitude must be a number between -90 and 90`;
       public longitude: string = `Tha longitude must be a number between -180 and 180`;
       public maxItemLength: string = `The maximum item length is {0} characters`;
       public maxLength: string = `The maximum length is {0} characters`;
       public maxValue: string = `The maximum value is {0}`;
       public minLength: string = `The minimum length is {0} characters`;
       public minValue: string = `The minimum value is {0}`;
       public notAllFieldsAreValid: string = `Not all fields are valid!`;
       public notValid: string = `{0} Not Valid`;
       public onlyPositiveNumbersAccepted: string = `Only positive numbers are accepted`;
       public passwordNotValid: string = `Password not valid (minimum is 8 characters)`;
       public pleaseInputCsvFile: string = `Please input a csv file`;
       public required: string = `The '{0}' field is required`;
       public theFileSelectedIsNotTheCorrectFormat: string = `{0} is not the correct format`;
       public timezone: string = `Timezone is not valid`;
       public userEmailValidation: string = `Email is already being used by another user.`;
       public userTeam: string = `New team is the same with the current team`;

		public static get Resources(): Validations{
			switch (Globals.currentActiveLanguage) {
				default:
				return new Validations();
		}
   	}
}
