import { AppThunkAction, StateDictionary } from ".";
import { Reducer, Action } from "redux";
import update from "immutability-helper";
import { ITabItem } from "../utils/reactUtils";

export module TabStore {

    export interface IState {
        activeTabId?: string | number,
        additionalProps?: any,
        tabs?: any
    }


    export enum Actions {
        ChangeActiveTab = "TAB_CHANGE_ACTIVE",
        InitializeTabPage = "TAB_INITIALIZE_PAGE",
        ResetTabsStates = "TAB_RESET"
    }

    interface IInitializeTabPage {
        type: Actions.InitializeTabPage,
        activeTabId: string,
        tabs: ITabItem[],
        additionalProps: any,
        componentId: string
    }

    interface IChangeActiveTab {
        type: Actions.ChangeActiveTab,
        tabId: string,
        componentId: string,
    }

    interface IResetTabsStates {
        type: Actions.ResetTabsStates
    }

    type KnownAction = IChangeActiveTab | IInitializeTabPage | IResetTabsStates;

    export const getActionCreators = (componentId: string) => ({
        initializeTabPage: (tabs: ITabItem[], activeTabId: string, additionalProps: any): AppThunkAction<KnownAction> => (dispatch, _) => {
            dispatch({ type: Actions.InitializeTabPage, activeTabId, tabs, additionalProps, componentId })
        },
        changeActiveTab: (tabId: string): AppThunkAction<KnownAction> => (dispatch, _) => {
            dispatch({ type: Actions.ChangeActiveTab, tabId, componentId });
        },
        resetTabsState: (): AppThunkAction<KnownAction> => (dispatch, _) => {
            dispatch({ type: Actions.ResetTabsStates });
        }
    });

    export const reducer: Reducer<StateDictionary<IState>> = (currentState: StateDictionary<IState>, incomingAction: Action) => {
        const action = incomingAction as KnownAction;

        const initialState: IState = {
        }

        switch (action.type) {
            case Actions.InitializeTabPage:
                return {
                    ...currentState,
                    [action.componentId]: {
                        activeTabId: action.activeTabId,
                        additionalProps: action.additionalProps,
                        tabs: action.tabs
                    }
                };
            case Actions.ChangeActiveTab:
                return update(currentState, {
                    [action.componentId]: {
                        activeTabId: { $set: action.tabId }
                    }
                });
            case Actions.ResetTabsStates:
                return {};
            default:
                return currentState || {};
        }
    }
}