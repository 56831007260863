import { Link } from "react-router-dom";
import { ApplicationPathBuilder } from "../../enums/Common/ApplicationPaths";
import { RoomBookingStatuses } from "../../enums/RoomBookings/RoomBookingStatuses";
import { RoomBookingTypes } from "../../enums/RoomBookings/RoomBookingTypes";
import RoomBookingTypeList from "../../resources/AdminUI/RoomBookings/RoomBookingTypeList";
import RoomBookingsGrid from "../../resources/AdminUI/RoomBookings/RoomBookingsGrid";
import { BaseEntity } from "../../utils/reactUtils";
import { String_Format, firstLetterToLowerCase } from "../../utils/utils";
import { DATE_FORMAT, formatDate, formatDateInZone, isSameDay } from "../../utils/date";
import { IRoomBookingsItem } from "./Interfaces/IRoomBookingsItem";
export class RoomBookingsItem extends BaseEntity implements IRoomBookingsItem {

    constructor(e: IRoomBookingsItem) {
        super();
        this.additionalConstructor(this, e);

        this.ForProperty(p => "id")
            .SetDisplayName(RoomBookingsGrid.Resources.visitId)
            .Show(_self => <div>
                <p>{String_Format(RoomBookingsGrid.Resources.id, this.id)}</p>
            </div>);

        this.ForProperty(p => "roomId")
            .SetDisplayName(RoomBookingsGrid.Resources.room)
            .Show(_self => <div>
                <p className="room-name">{this.roomName}</p>
                <p>{String_Format(RoomBookingsGrid.Resources.roomId, this.roomId)}</p>
            </div>);

        this.ForProperty(p => "workspaceId")
            .SetDisplayName(RoomBookingsGrid.Resources.space)
            .Show(_self => <div>
                <p className="room-name">{this.workspaceName}</p>
                <p>{String_Format(RoomBookingsGrid.Resources.spaceId, this.workspaceId)}</p>
            </div>);

        this.ForProperty(p => "bookingTypeId")
            .SetDisplayName(RoomBookingsGrid.Resources.bookingInfo)
            .Show(_self => {
                var type = RoomBookingTypes[this.bookingTypeId]
                var typeName = RoomBookingTypeList.Resources[firstLetterToLowerCase(type)]
                switch (this.bookingTypeId) {
                    case RoomBookingTypes.Hourly:
                        return <div className="column-large">
                            <p>{typeName}</p>
                            <p className="timestamp">
                                {formatDateInZone(this.startsAt, this.timezone)}
                                - {formatDateInZone(this.endsAt, this.timezone)}
                                <span className="timezone"> {this.timezone}</span>
                            </p>
                        </div>
                    case RoomBookingTypes.Daily:
                        return <div className="column-large">
                            <p>{String_Format(RoomBookingsGrid.Resources.bookingAmount, typeName, this.bookingAmount)}</p>
                            <p className="timestamp">
                                {
                                    isSameDay(this.startsAt, this.endsAt)
                                        ? formatDateInZone(this.startsAt, this.timezone, DATE_FORMAT)
                                        : formatDateInZone(this.startsAt, this.timezone, DATE_FORMAT) + " - " + formatDateInZone(this.endsAt, this.timezone, DATE_FORMAT)
                                }
                                <span className="timezone"> {this.timezone}</span>
                            </p>
                        </div>
                    case RoomBookingTypes.Monthly:
                        return <div className="column-large">
                            <p>{String_Format(RoomBookingsGrid.Resources.bookingAmount, typeName, this.bookingAmount * 30)}</p>
                            <p className="timestamp">
                                {formatDateInZone(this.startsAt, this.timezone, DATE_FORMAT)}
                                - {formatDateInZone(this.endsAt, this.timezone, DATE_FORMAT)}
                                <span className="timezone"> {this.timezone}</span>
                            </p>
                        </div>
                }
            });
            
            this.ForProperty(p => "createdAt")
            .SetDisplayName(RoomBookingsGrid.Resources.requestTimestamp)
            .Show(_self => <div className="column-medium">
                <p className="timestamp">{formatDate(this.createdAt)}</p>
            </div>
            );

        this.ForProperty(p => "statusId")
            .SetDisplayName(RoomBookingsGrid.Resources.status)
            .Show(_self => {
                return <div>
                    <p>{this.statusName}</p>
                </div>
            });

            this.ForProperty(p => "userId")
            .Show(_self => <div>
                <div className="span-details-row">
                    <div>
                        {RoomBookingsGrid.Resources.fullName} <span>{this.userFirstName} {this.userLastName} | {String_Format(RoomBookingsGrid.Resources.id, this.userId)}</span>
                    </div>
                </div>

                <div className="span-details-row">
                    {
                        this.statusId == RoomBookingStatuses.Cancelled
                            ? <div className="span-details-row">
                                {RoomBookingsGrid.Resources.cancelledAt}
                                <span className="timestamp">{formatDate(this.cancelledAt)}</span>
                            </div>
                            : null
                    }
                    {
                        this.statusId == RoomBookingStatuses.Expired
                            ? <div className="span-details-row">
                                {RoomBookingsGrid.Resources.expiredAt}
                                <span className="timestamp">{formatDate(this.expiresAt)}</span>
                            </div>
                            : null
                    }
                    {
                        this.statusId == RoomBookingStatuses.Rejected
                            ? <div className="span-details-row">
                                {RoomBookingsGrid.Resources.rejectedAt}
                                <span className="timestamp">{formatDate(this.rejectedAt)}</span>
                            </div>
                            : null
                    }
                </div>
            </div>
            );

    }

    id: number;
    startsAt: string;
    unixStartsAtWithTimezone: number;
    endsAt: string;
    cancelledAt: string;
    expiresAt: string;
    rejectedAt: string;
    workspaceId: number;
    workspaceName: string;
    roomId: number;
    roomName: string;
    bookingTypeId: number;
    bookingAmount: number;
    userId: number;
    userFirstName: string;
    userLastName: string;
    timezone: string;
    statusId: number;
    statusName: string;
    createdAt: string;
}