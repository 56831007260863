import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ApplicationState } from "../../store";
import PaginationBar from "../Shared/PaginationBar";
import General from "../../resources/AdminUI/General";
import { RoomBookingsItem } from "../../models/RoomBookings/RoomBookingsItem";
import { RoomBookingsGridStore } from "../../store/RoomBookingsGridStore";
import bind from "bind-decorator";
import update from "immutability-helper";
import {
  firstLetterToLowerCase,
  getPromiseFromAction,
} from "../../utils/utils";
import IPageIndex from "../../models/Pagination/IPageIndex";
import { RoomBookingStatuses } from "../../enums/RoomBookings/RoomBookingStatuses";
import { NotificationStore } from "../../store/NotificationStore";
import RoomBookingsService from "../../services/RoomBookingsService";
import RoomBookingsGrid from "../../resources/AdminUI/RoomBookings/RoomBookingsGrid";
import { RoomBookingRejectReasons } from "../../enums/RoomBookings/RoomBookingRejectReasons";
import DeclineRoomBookingConfirmationModal from "./DeclineRoomBookingConfirmationModal";
import RoomBookingRejectReasonList from "../../resources/AdminUI/RoomBookings/RoomBookingRejectReasonList";
import { Modal } from "antd";
import ErrorMessages from "../../resources/Common/ErrorMessages";
import { withRouter } from "../Shared/withRouter";
import ModalDialog from "../Shared/ModalDialog";
import Loading from "../Shared/Loading";
const { confirm } = Modal;

interface IProps {
  roomBookings?: RoomBookingsItem[];
  loading?: boolean;
  hiddenColumns?: string[];
  expandableColumns?: string[];
  isRoomBookingHiddenList: boolean[];
  filters?: string;
  numberOfRoomBookings?: number;
  itemsPerPage?: number;
  firstIndexFromPage?: number;
  lastIndexFromPage?: number;
  pageIndexArray?: IPageIndex[];
  showPendingActionsColumn?: boolean;

  reload?: () => void;
  changeCurrentPage?: (currentPage: number) => void;
  initialize?: (
    defaultSelectedItemsPerPageOption: number,
    filters: string
  ) => void;
  initializePendingRoomRequestsPage?: (
    defaultSelectedItemsPerPageOption: number
  ) => void;
  showErrorNotification?: () => void;
}

interface IState {
  isRoomBookingHiddenList: boolean[];
  isDeclineModalOpen: boolean;
  selectedRoomBooking?: RoomBookingsItem;
}

class RoomBookingsTable extends React.PureComponent<any, IState> {
  constructor(props) {
    super(props);

    this.state = {
      isRoomBookingHiddenList: [],
      isDeclineModalOpen: false,
    };
  }
  public static defaultProps: Partial<IProps> = {
    loading: false,
  };
  tableClass: string = "table";

  itemsPerPage: number = 15;
  maxPagesDisplayed: number = 3;
  pageNeighbours: number = 1;
  hasRefreshButton: boolean = false;

  @bind
  toggleIsExpanded(index) {
    this.setState(
      update(this.state, {
        isRoomBookingHiddenList: {
          [index]: { $set: !this.state.isRoomBookingHiddenList[index] },
        },
      })
    );
  }

  @bind
  changeCurrentPage(currentPage: number) {
    this.props.changeCurrentPage(currentPage);
    this.setState({
      isRoomBookingHiddenList: this.props.isRoomBookingHiddenList,
    });
  }

  @bind
  showAcceptRoomBookingModal(id) {
    confirm({
      title: RoomBookingsGrid.Resources.acceptRoomRequest,
      content: (
        <div>
          <p>
            {RoomBookingsGrid.Resources.acceptRoomRequestConfirmationMessage}
          </p>
        </div>
      ),
      okText: RoomBookingsGrid.Resources.accept,
      okType: "primary",
      cancelText: General.Resources.cancelButtonLabel,
      onOk: () => {
        this.acceptRoomBooking(id);
      },
      onCancel: () => {},
    });
  }

  @bind
  async acceptRoomBooking(id) {
    await RoomBookingsService.acceptRoomBooking(id).then((res) => {
      if (!res || res.hasErrors) {
        Modal.error({
          title: ErrorMessages.Resources.generalErrorMessage,
        });
      } else {
        this.props.reload();
      }
    });
  }

  @bind
  hideDeclineRoomBookingModal() {
    this.setState({ isDeclineModalOpen: false });
  }

  @bind
  showDeclineRoomBookingModal(booking: RoomBookingsItem) {
    this.setState({ isDeclineModalOpen: true, selectedRoomBooking: booking });
  }

  @bind
  getDeclineReasonsList() {
    var options = [];
    let keys = Object.keys(RoomBookingRejectReasons).filter(
      (k) => typeof RoomBookingRejectReasons[k as any] === "number"
    );

    keys.forEach((key) => {
      let value = RoomBookingRejectReasons[key as any].toString();
      options.push({
        value: value,
        label:
          RoomBookingRejectReasonList.Resources[firstLetterToLowerCase(key)],
      });
    });
    return options;
  }

  @bind
  async declineRoomBooking(id, reasonId) {
    await RoomBookingsService.rejectRoomBooking(id, reasonId).then((res) => {
      if (!res || res.hasErrors) {
        Modal.error({
          title: ErrorMessages.Resources.generalErrorMessage,
        });
      } else {
        this.props.reload();
      }
    });
  }

  componentDidMount(): void {
    if (!this.props.roomBookings || this.props.roomBookings.length == 0) {
      getPromiseFromAction(
        this.props.initialize(this.itemsPerPage, this.props.filters)
      ).then(() =>
        this.setState({
          isRoomBookingHiddenList: this.props.isRoomBookingHiddenList,
        })
      );
    } else if (this.props.filters) {
      getPromiseFromAction(this.props.reload()).then(() =>
        this.setState({
          isRoomBookingHiddenList: this.props.isRoomBookingHiddenList,
        })
      );
    } else {
      this.setState({
        isRoomBookingHiddenList: this.props.isRoomBookingHiddenList,
      });
    }
  }

  render() {
    if (this.props.loading) {
      return (
        <div>
          <Loading />
        </div>
      );
    }
    if (!this.props.roomBookings || this.props.roomBookings.length == 0) {
      return <div>{General.Resources.noElements}</div>;
    } else {
      return (
        <div>
          <div className="table-wrapper">
            <table className={this.tableClass}>
              <thead>
                <tr>
                  <th></th>
                  {this.props.roomBookings[0].TrueProperties.filter(
                    (p) => this.props.hiddenColumns.indexOf(p) < 0
                  ).map((p) => (
                    <th key={p}>
                      {this.props.roomBookings[0].GetDisplayNameForProperty(p)}
                    </th>
                  ))}
                  {this.props.showPendingActionsColumn ? (
                    <th className="grid-sortable-column">
                      <span>{General.Resources.actions}</span>
                    </th>
                  ) : null}
                </tr>
              </thead>
              <tbody>
                {this.props.roomBookings.map((us, index) => {
                  const columCount =
                    us.TrueProperties.filter(
                      (p) => this.props.hiddenColumns.indexOf(p) < 0
                    ).length + 2;

                  return (
                    <React.Fragment key={us.id}>
                      <tr>
                        <td className="btn-row-expand">
                          <div onClick={() => this.toggleIsExpanded(index)}>
                            <span className="material-symbols-outlined">
                              {this.state.isRoomBookingHiddenList.length > 0 &&
                              this.state.isRoomBookingHiddenList[index]
                                ? "chevron_right"
                                : "expand_more"}
                            </span>
                          </div>
                        </td>
                        {us.TrueProperties.filter(
                          (p) => this.props.hiddenColumns.indexOf(p) < 0
                        ).map((p) => (
                          <td
                            data-label={
                              this.props.hasHeader
                                ? this.props.roomBookings[0].GetColumnNameForProperty(
                                    p
                                  )
                                : null
                            }
                            key={p}
                          >
                            {us.GetDisplayValueForProperty(p)}
                          </td>
                        ))}

                        {this.props.showPendingActionsColumn &&
                        us.statusId.toString() ==
                          RoomBookingStatuses.Pending.toString() ? (
                          <>
                            <div className="btn-row-actions">
                              <div
                                className="btn-big btn-secondary"
                                onClick={() =>
                                  this.showDeclineRoomBookingModal(us)
                                }
                              >
                                {General.Resources.decline}
                              </div>
                              <ModalDialog
                                title={
                                  RoomBookingsGrid.Resources.declineRoomRequest
                                }
                                open={
                                  this.state.isDeclineModalOpen &&
                                  this.state.selectedRoomBooking == us
                                }
                                onCancel={this.hideDeclineRoomBookingModal}
                              >
                                <DeclineRoomBookingConfirmationModal
                                  onCancelCallback={
                                    this.hideDeclineRoomBookingModal
                                  }
                                  onSaveCallback={this.declineRoomBooking}
                                  id={us.id}
                                  reasons={this.getDeclineReasonsList()}
                                />
                              </ModalDialog>

                              <div
                                className="btn-big btn-primary"
                                onClick={() =>
                                  this.showAcceptRoomBookingModal(us.id)
                                }
                              >
                                {General.Resources.accept}
                              </div>
                            </div>
                          </>
                        ) : null}
                      </tr>
                      <tr
                        className={
                          this.state.isRoomBookingHiddenList.length > 0 &&
                          this.state.isRoomBookingHiddenList[index]
                            ? "hidden-row"
                            : null
                        }
                      >
                        {us.TrueProperties.filter(
                          (p) => this.props.expandableColumns.indexOf(p) >= 0
                        ).map((p) => (
                          <td
                            className="colspan-row"
                            colSpan={columCount}
                            data-label={
                              this.props.hasHeader
                                ? this.props.roomBookings[0].GetColumnNameForProperty(
                                    p
                                  )
                                : null
                            }
                            key={p}
                          >
                            {us.GetDisplayValueForProperty(p)}
                          </td>
                        ))}
                      </tr>
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
          <PaginationBar
            useItemsPerPageOptions={false}
            totalItems={this.props.numberOfRoomBookings}
            reload={this.props.reload}
            changeCurrentPage={this.changeCurrentPage}
            itemsPerPage={this.props.itemsPerPage}
            firstIndexFromPage={this.props.firstIndexFromPage}
            lastIndexFromPage={this.props.lastIndexFromPage}
            pageIndexArray={this.props.pageIndexArray}
            maxPagesDisplayed={this.maxPagesDisplayed}
            pageNeighbours={this.pageNeighbours}
            hasRefreshButton={this.hasRefreshButton}
          />
        </div>
      );
    }
  }
}

export default withRouter(
  connect(
    (state: ApplicationState, ownProps: any) => {
      return state.roomBookingsGrid[ownProps.componentId] || {};
    },
    (dispatch, ownProps: any) =>
      bindActionCreators(
        {
          ...RoomBookingsGridStore.getActionCreators(ownProps.componentId),
          showErrorNotification:
            NotificationStore.actionCreators.showErrorNotification,
        },
        dispatch
      )
  )(RoomBookingsTable)
);
