import bind from "bind-decorator";
import React from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ApplicationState } from "../../store";
import {
  String_Format,
  firstLetterToLowerCase,
  getPromiseFromAction,
  nameof,
} from "../../utils/utils";
import { DefaultFilterValues } from "../../enums/Common/DefaultFilterValues";
import { LookupStore } from "../../store/LookupStore";
import RegularExpressions from "../../resources/Common/RegularExpressions";
import { DatePicker, Select } from "antd";
import { RoomBookingsGridStore } from "../../store/RoomBookingsGridStore";
import RoomBookingsTable from "../../components/RoomBookings/RoomBookingsTable";
import { RoomBookingStatuses } from "../../enums/RoomBookings/RoomBookingStatuses";
import RoomBookingStatusTypes from "../../resources/AdminUI/RoomBookings/RoomBookingStatusTypes";
import RoomBookingsGrid from "../../resources/AdminUI/RoomBookings/RoomBookingsGrid";
import { NavigateFunction } from "react-router-dom";
import { IAntdSelectListItem } from "../../models/Common/IAntdSelectListItem";
import dayjs from "dayjs";
import { withRouter } from "../../components/Shared/withRouter";
import General from "../../resources/AdminUI/General";
import Loading from "../../components/Shared/Loading";
const { RangePicker } = DatePicker;

interface IProps {
  filters: string;
  searchTerm: string;
  singleSelectedStatus: string;
  singleSelectedWorkspace: string;
  startDateSearchTemplate: number;
  endDateSearchTemplate: number;
  workspaces: IAntdSelectListItem[];

  navigate: NavigateFunction;

  initialize: (defaultSelectedItemsPerPageOption: number) => void;
  reload: () => Promise<any>;
  changeCurrentPage: (currentPage: number) => void;
  getGetWorkspaces: (addDefaultOption: boolean) => void;
  setFilters: (filters: string) => void;
  setSearchTerm: (searchTerm: string) => void;
  setSingleSelectedStatus: (singleSelectedStatus: string) => void;
  setSingleSelectedWorkspace: (singleSelectedWorkspace: string) => void;
  setStartDateSearchTemplateAndEndDateSearchTemplate: (
    startDateSearchTemplate: number,
    endDateSearchTemplate: number
  ) => void;
}

class RoomBookingsGridPage extends React.PureComponent<any, any> {
  constructor(props) {
    super(props);
    this.state = {};
    this.props.getGetWorkspaces(true);
  }

  nameSearchTemplate = `contains(concat(concat(tolower(UserFirstName), ' '), tolower(UserLastName)), tolower('{0}'))`;
  idSearchTemplate = ` or Id eq {0}`;
  statusFilterTemplate = `StatusId eq {0}`;
  workspaceFilterTemplate = "WorkspaceId eq {0}";
  startDateFilterTemplate = `UnixStartsAtWithTimezone eq {0}`;
  startAndEndDateFilterTemplate = `UnixStartsAtWithTimezone ge {0} and UnixStartsAtWithTimezone le {1}`;
  dateFormat = "DD-MM-YYYY";

  @bind
  rebuildODataAndGetData() {
    var filters = [];
    if (this.props.searchTerm) {
      var searchFilter = String_Format(
        this.nameSearchTemplate,
        this.props.searchTerm
      );
      var pattern = RegularExpressions.Resources.onlyNumbers;
      if (RegExp(pattern).test(this.props.searchTerm)) {
        searchFilter = searchFilter.concat(
          String_Format(this.idSearchTemplate, this.props.searchTerm)
        );
      }
      filters.push(`(${searchFilter})`);
    }

    this.props.singleSelectedStatus &&
    this.props.singleSelectedStatus != DefaultFilterValues.All.toString()
      ? filters.push(
          `(${String_Format(
            this.statusFilterTemplate,
            this.props.singleSelectedStatus
          )})`
        )
      : null;

    this.props.singleSelectedWorkspace &&
    this.props.singleSelectedWorkspace != DefaultFilterValues.All.toString()
      ? filters.push(
          `(${String_Format(
            this.workspaceFilterTemplate,
            this.props.singleSelectedWorkspace
          )})`
        )
      : null;

    if (
      this.props.startDateSearchTemplate &&
      this.props.endDateSearchTemplate
    ) {
      if (
        this.props.startDateSearchTemplate == this.props.endDateSearchTemplate
      ) {
        var startDatefilter = String_Format(
          this.startDateFilterTemplate,
          this.props.startDateSearchTemplate
        );
        filters.push(`(${startDatefilter})`);
      } else {
        var startAndEndDatefilter = String_Format(
          this.startAndEndDateFilterTemplate,
          this.props.startDateSearchTemplate,
          this.props.endDateSearchTemplate
        );
        filters.push(`(${startAndEndDatefilter})`);
      }
    }

    var oDataFilter = filters.join(" and ");
    this.props.setFilters(oDataFilter);
    this.props.changeCurrentPage(1);
    getPromiseFromAction(this.props.reload()).then(() =>
      this.props.changeCurrentPage(1)
    );
  }

  @bind
  onSearchFilterChange(e: React.FormEvent<HTMLInputElement>) {
    let searchTerm = e.currentTarget.value;
    if (this.props.searchTerm != searchTerm) {
      getPromiseFromAction(this.props.setSearchTerm(searchTerm)).then(() => {
        this.rebuildODataAndGetData();
      });
    }
  }

  @bind
  onStatusFilterChange(value: string, option: any) {
    getPromiseFromAction(this.props.setSingleSelectedStatus(value)).then(() => {
      this.rebuildODataAndGetData();
    });
  }

  @bind
  onWorkspaceFilterChange(value: string, values: any) {
    getPromiseFromAction(this.props.setSingleSelectedWorkspace(value)).then(
      () => {
        this.rebuildODataAndGetData();
      }
    );
  }

  @bind
  onDateChange(dates: [any, any], dateStrings: [string, string]) {
    if (dateStrings[0] == dateStrings[1]) {
      var startDate = dateStrings[0].split("-").reverse().join("-");
      var startDateSearchTemplate = parseInt(
        (new Date(startDate).getTime() / 1000).toFixed(0)
      );
      getPromiseFromAction(
        this.props.setStartDateSearchTemplateAndEndDateSearchTemplate(
          startDateSearchTemplate,
          startDateSearchTemplate
        )
      ).then(() => {
        this.rebuildODataAndGetData();
      });
    } else {
      var startDate = dateStrings[0].split("-").reverse().join("-");
      var endDate = dateStrings[1].split("-").reverse().join("-");
      var startDateSearchTemplate = parseInt(
        (new Date(startDate).getTime() / 1000).toFixed(0)
      );
      var endDateSearchTemplate = parseInt(
        (new Date(endDate).getTime() / 1000).toFixed(0)
      );
      getPromiseFromAction(
        this.props.setStartDateSearchTemplateAndEndDateSearchTemplate(
          startDateSearchTemplate,
          endDateSearchTemplate
        )
      ).then(() => {
        this.rebuildODataAndGetData();
      });
    }
  }

  @bind
  getAllRoomStatuses() {
    var options = [];
    let keys = Object.keys(RoomBookingStatuses).filter(
      (k) => typeof RoomBookingStatuses[k as any] === "number"
    );

    keys.forEach((key) => {
      let value = RoomBookingStatuses[key as any].toString();
      options.push({
        value: value,
        label: RoomBookingStatusTypes.Resources[firstLetterToLowerCase(key)],
      });
    });
    return options;
  }

  render() {
    if (!this.props.workspaces) {
      return (
        <div>
          <Loading />
        </div>
      );
    }
    return (
      <div>
        <Helmet title={RoomBookingsGrid.Resources.roomsLogs} />
        <div className="page-top-container">
          <div className="page-title">
            {RoomBookingsGrid.Resources.roomsLogs}
          </div>
        </div>
        <div className="filter-bar">
          <div className="filter-search">
            <div className="input-group">
              <span className="icon material-symbols-outlined">search</span>
              <input
                type="text"
                className="form-control"
                placeholder={RoomBookingsGrid.Resources.searchByLogIdOrUserName}
                onChange={this.onSearchFilterChange}
                name="searchTerm"
                value={this.props.searchTerm}
              />
            </div>
          </div>

          <div className="filter-item">
            <div className="input-group">
              <Select
                options={this.props.workspaces}
                onChange={this.onWorkspaceFilterChange}
                value={this.props.singleSelectedWorkspace}
                showSearch
                optionFilterProp="label"
              />
            </div>
          </div>

          <div className="filter-item">
            <div className="input-group">
              <Select
                options={this.getAllRoomStatuses()}
                onChange={this.onStatusFilterChange}
                value={this.props.singleSelectedStatus}
              ></Select>
            </div>
          </div>

          <RangePicker
            value={[
              this.props.startDateSearchTemplate
                ? dayjs.unix(this.props.startDateSearchTemplate)
                : this.props.startDateSearchTemplate,
              this.props.endDateSearchTemplate
                ? dayjs.unix(this.props.endDateSearchTemplate)
                : this.props.endDateSearchTemplate,
            ]}
            format={this.dateFormat}
            onChange={this.onDateChange}
          />
        </div>

        <RoomBookingsTable
          componentId={componentId}
          hiddenColumns={[
            "workspaceName",
            "startsAt",
            "unixStartsAtWithTimezone",
            "createdAt",
            "endsAt",
            "cancelledAt",
            "expiresAt",
            "rejectedAt",
            "statusName",
            "bookingAmount",
            "userId",
            "userFirstName",
            "userLastName",
            "roomName",
            "timezone",
          ]}
          expandableColumns={["userId"]}
          filters={this.props.filters}
          showActionsColumn={true}
        />
      </div>
    );
  }
}

const componentId = "RoomBookingGridPage";

export default withRouter(
  connect(
    (state: ApplicationState) => {
      const componentState = state.roomBookingsGrid[componentId];

      return {
        filters: componentState?.filters || "",
        searchTerm: componentState?.searchTerm || "",
        singleSelectedStatus: componentState?.singleSelectedStatus || "0",
        singleSelectedWorkspace: componentState?.singleSelectedWorkspace || "0",
        startDateSearchTemplate:
          componentState?.startDateSearchTemplate || null,
        endDateSearchTemplate: componentState?.endDateSearchTemplate || null,
        workspaces: state.lookup.groupWorkspaces,
      };
    },
    (dispatch) =>
      bindActionCreators(
        {
          ...RoomBookingsGridStore.getActionCreators(componentId),
          getGetWorkspaces: LookupStore.actionCreators.getGetGroupWorkspaces,
        },
        dispatch
      )
  )(RoomBookingsGridPage)
);
