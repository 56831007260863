import { ServiceBase } from "./ServiceBase";
import Result from "../models/Common/Result";
import { AppSettings } from "../utils/settings";
import { IAntdSelectListItem } from "../models/Common/IAntdSelectListItem";

export default class LookupService extends ServiceBase {

    static getGroupWorkspaces = (): Promise<Result<IAntdSelectListItem[]>> => {

        try {
            var result = LookupService.requestJsonFetch<IAntdSelectListItem[]>({
                method: "GET",
                url: `${AppSettings.CommonSettings.ApiUrl}/lookup/workspaces`
            });
            return result;
        } catch (error) {
        }

        return new Promise<Result<IAntdSelectListItem[]>>(null);
    }

}