import { DateTimeFormatter, ZoneId, ZonedDateTime } from "@js-joda/core";
import { Locale } from "@js-joda/locale_en";
require("@js-joda/timezone");

export const MONTH_FORMAT = 'MMM yyyy';
export const DATE_FORMAT = 'd MMM yyyy';
export const DATE_TIME_FORMAT = 'd MMM yyyy, HH:mm';

export function formatDate(isoDate: string, format: string = DATE_TIME_FORMAT): string {
    return formatDateInZone(isoDate, ZoneId.SYSTEM, format);
}

export function formatDateInZone(isoDate: string, zone: string | ZoneId, format: string = DATE_TIME_FORMAT): string {
    if (isoDate) {
        const zoneId = (typeof zone === 'string' || zone instanceof String) ? ZoneId.of(zone as string) : zone;

        const date = ZonedDateTime.parse(isoDate).withZoneSameInstant(zoneId);
        const formatter = DateTimeFormatter.ofPattern(format).withLocale(Locale.ENGLISH);
        return date.format(formatter);
    }
    return null;
}

export function isSameDay(isoDate1: string, isoDate2: string) {
    if (isoDate1 && isoDate2) {
        let date1 = ZonedDateTime.parse(isoDate1).withZoneSameInstant(ZoneId.SYSTEM);
        let date2 = ZonedDateTime.parse(isoDate2).withZoneSameInstant(ZoneId.SYSTEM);
        return date1.year() == date2.year()
            && date1.month() == date2.month()
            && date1.dayOfMonth() == date2.dayOfMonth();
    }
    return null;
}