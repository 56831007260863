import React from "react";
import General from "../../resources/AdminUI/General";


interface IProps {
    currentMonthCheckins: number;
    currentMonthUsers: number;
    totalCheckins: number;
    totalUsers: number;
}

interface IState { }

export default class SpaceTotalCheckinsCard extends React.PureComponent<IProps, IState>{
    constructor(props) {
        super(props);
    }


    render() {
        return <div className="el-tabs__content">
            <div className="el-tab-pane info_check-ins">
                <div className="info_check-ins_month">
                    <div className="info_check-in_description">{General.Resources.thisMonth}</div>
                    <div className="info_check-in_number_check-ins">{this.props.currentMonthCheckins}</div>
                    <div className="info_check-in_no_users">
                        <div className="info_check-in_icon">
                            <span className="material-symbols-outlined">{General.Resources.group}</span>
                        </div>
                        <div className="info_check-in_number_users">{this.props.currentMonthUsers}</div>
                    </div>
                </div>
                <div className="info_check-ins_month">
                    <div className="info_check-in_description">{General.Resources.overall}</div>
                    <div className="info_check-in_number_check-ins">{this.props.totalCheckins}</div>
                    <div className="info_check-in_no_users">
                        <div className="info_check-in_icon">
                            <span className="material-symbols-outlined">{General.Resources.group}</span>
                        </div>
                        <div className="info_check-in_number_users">{this.props.totalUsers}</div>
                    </div>
                </div>
            </div>
        </div>
    }
}

