import Globals from "./../../Globals";

export default class ErrorMessages {
       public error: string = `Error`;
       public errorForFollowingEmails: string = `Error for following emails: {0} `;
       public generalErrorMessage: string = `Something went wrong`;
       public invalidValueForArgumentId: string = `Invalid value for argument id`;
       public notAllowed: string = `Not allowed`;
       public unauthorizedErrorMessage: string = `You are not allowed to access this resource`;

		public static get Resources(): ErrorMessages{
			switch (Globals.currentActiveLanguage) {
				default:
				return new ErrorMessages();
		}
   	}
}
