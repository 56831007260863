import Result from "../models/Common/Result";
import React from "react";
import { IEntitiesList } from "../models/Common/IEntitiesList";
import { GetRealPropertiesOfObject, MapObject, String_Format } from "./utils";
import { ISelectListItem } from "../models/Common/ISelectListItem";
import FormInput from "../components/Shared/FormInput";
import { AppThunkAction } from "../store/index";
import Validations from "../resources/Common/Validations";

export interface IGridService<T> {
    getData: (toTake: number, toSkip: number, searchTerm?: string, sortByTerm?: string, sortByDirection?: string, additionalGetDataParameters?: any) => Promise<Result<IEntitiesList<T>>>;
    additionalMapping?: (value: IEntitiesList<T>) => IEntitiesList<T>;
}

export interface IFormService<T> {
    getEntityForEdit: (id: string) => Promise<Result<T>>;
    initializeEntity: (propertyUpdater: (propName: string, data: any) => AppThunkAction<any>) => T;
    additionalMapping?: (value: IEditableEntity | any, propertyUpdater: (propName: string, data: any) => AppThunkAction<any>) => T;
    addEntity: (entity: T | any, additionalModel?: any) => Promise<Result<any>>;
    updateEntity: (entity: T | any, additionalModel?: any) => Promise<Result<any>>;
    successMessage?: string;
    errorMessage?: string;
}

export interface IGridFormService<T> {
    getEntitiesForEdit: (id: string) => Promise<Result<T[] | any>>;
    initializeEntity: (isAddForm: boolean, propertyUpdater: (itemKey: string, propName: string, data: any) => AppThunkAction<any>) => IGridFormEntity<T>;
    additionalMapping?: (value: IEditableEntity[] | any, propertyUpdater: (itemKey: string, propName: string, data: any) => AppThunkAction<any>) => IGridFormEntity<T | any>[];
    addEntities: (entities: T[] | any) => Promise<Result<any>>;
    updateEntities: (id: string, entities: T[] | any) => Promise<Result<any>>;
    successMessage?: string;
    errorMessage?: string;
}

export interface IEntity {

}

export interface IEditableEntity {

}

export interface IGridFormEntity<T extends IEditableEntity> {
    itemKey: string,
    entity: T
}

export interface ITypeBasedDisplay {
    (s: any): string;
}

export interface IInputType {
    inputType: EntityFieldInputType,
    propertyType?: EntityPropertyTypes,
    className?: string,
    getDynamicDropDownData?: (...args: any[]) => Promise<ISelectListItem[]>,
    getDynamicDropDownDataArgs?: any[],
    staticDropDownData?: ISelectListItem[],
    placeholder?: string,
    hidden?: boolean,
    readOnly?: boolean,
}
export class FormProperty implements IInputType {
    PropertyName: string;
    DisplayName: string;
    DisplayMethod: (...args: any[]) => JSX.Element;
    Args: any[];
    placeholder?: string;
    inputType: EntityFieldInputType;
    searchByTermPropertyName: string;
    searchByTermPropertyIdIsGuid: boolean;
    className?: string;
    propertyType?: EntityPropertyTypes;
    getDynamicDropDownData?: (...args: any[]) => Promise<ISelectListItem[]>;
    getDynamicDropDownDataArgs?: any[];
    staticDropDownData: ISelectListItem[];
    hidden?: boolean;
    readOnly?: boolean;
    isIgnored?: boolean;
    isCustomShow: boolean = false;
    validations?: Array<(value: any) => string> = [];
    readOnlyFunc?: () => boolean;


    public Show = (f: (...args: any[]) => JSX.Element, ...args: any[]): FormProperty => { this.DisplayMethod = f; this.Args = args; return this; };
    public HasCustomDisplay = (): FormProperty => { this.isCustomShow = true; return this; }
    public SetDisplayName = (name: string): FormProperty => { this.DisplayName = name; return this; } 
    public SetInputType = (inputType: EntityFieldInputType): FormProperty => { this.inputType = inputType; return this; }
    public SetPropertyType = (type: EntityPropertyTypes): FormProperty => { this.propertyType = type; return this; }
    public SetSearchByTermPropertyName = (term: string): FormProperty => { this.searchByTermPropertyName = term; return this; }
    public SetSearchByTermPropertyIdIsGuid = (): FormProperty => { this.searchByTermPropertyIdIsGuid = true; return this; }
    public SetPlaceHolder = (placeholder: string): FormProperty => { this.placeholder = placeholder; return this; }
    public SetClassName = (className: string): FormProperty => { this.className = className; return this; }
    public SetGetDynamicDropDownDataFunction = (getter: (...args: any[]) => Promise<ISelectListItem[]>): FormProperty => { this.getDynamicDropDownData = getter; return this; }
    public SetDynamicDropDownDataArgs = (args: any[]) => { this.getDynamicDropDownDataArgs = args; return this; }
    public SetStaticDropDownData = (data: ISelectListItem[]) => { this.staticDropDownData = data; return this; }
    public SetHidden = (isHidden: boolean) => { this.hidden = isHidden; return this; }
    public SetIgnored = (isIgnored: boolean) => { this.isIgnored = isIgnored; return this; }
    public SetReadOnly = (isReadOnly: boolean) => { this.readOnly = isReadOnly; return this; }
    public SetReadOnlyFunc = (isReadOnly: () => boolean) => { this.readOnlyFunc = isReadOnly; return this; }

    public IsRequired = () => {
        this.validations.push((value: any) => {
            if (this.inputType != EntityFieldInputType.MultipleSelect && this.inputType != EntityFieldInputType.Checkbox) {
                if (this.inputType == EntityFieldInputType.Text || this.inputType == EntityFieldInputType.Password || this.inputType == EntityFieldInputType.MultipleSearchDropDown) {
                    if (!value || value.length == 0) {
                        return String_Format(Validations.Resources.required, this.DisplayName);
                    }
                }
                else {
                    if (this.inputType != EntityFieldInputType.File && !value) {
                        return String_Format(Validations.Resources.required, this.DisplayName);
                    }
                    else if (this.inputType == EntityFieldInputType.File && (!value || value.name === "")) {
                        return String_Format(Validations.Resources.required, this.DisplayName);
                    }
                }
            }
            else {
                if (value.filter(v => v.selected == true).length == 0) {
                    return String_Format(Validations.Resources.required, this.DisplayName);
                }
            }
            return null;
        });
        return this;
    }
    public SetMinVal = (minVal: number) => {
        this.validations.push((value: any) => {
            if (this.inputType == EntityFieldInputType.Number && +value < minVal) {
                return String_Format(Validations.Resources.minValue, minVal);
            }
            return null;
        });
        return this;
    }
    public SetMaxVal = (maxVal: number) => {
        this.validations.push((value: any) => {
            if (this.inputType == EntityFieldInputType.Number && +value > maxVal) {
                return String_Format(Validations.Resources.maxValue, maxVal);
            }
            return null;
        });
        return this;
    }
    public SetMaxLength = (maxLength) => {               
        this.validations.push((value: any) => {
            if (value.length > maxLength) {
                return String_Format(Validations.Resources.maxLength, maxLength);
            }
            return null;
        });
        return this;
    }
    public SetMinLength = (minLength: number) => {
        this.validations.push((value: any) => {
            if (value.length < minLength) {
                return String_Format(Validations.Resources.minLength, minLength);
            }
            return null;
        });
        return this;
    }
    public SetExactLength = (exactLength: number) => {
        this.validations.push((value: any) => {
            if (value && value.length != exactLength) {
                return String_Format(Validations.Resources.fixedLength, exactLength);
            }
            return null;
        });
        return this;
    }
    public SetCustomValidation = (func: (value: any) => string): FormProperty => {
        this.validations.push(func);
        return this;
    }
}

export class BaseFormEntity implements IEditableEntity {
    private displaysForProperties: FormProperty[];
    public TrueProperties: string[];

    constructor() {
        this.displaysForProperties = new Array<FormProperty>();
    }

    public additionalConstructor(child: BaseFormEntity, dataSource: any, propertyUpdater?: (propName: string, data: any) => AppThunkAction<any>) {
        child.TrueProperties = GetRealPropertiesOfObject(child, new BaseFormEntity());
        child.RegisterDefaultDisplaysForProperties(propertyUpdater);
        MapObject(dataSource, child);
    }

    public additionalGridFormConstructor(child: BaseFormEntity, dataSource: any) {
        child.TrueProperties = GetRealPropertiesOfObject(child, new BaseFormEntity());
        MapObject(dataSource, child);
    }

    public ForProperty = (predicate: (entity: this) => string): FormProperty => {
        var self = this;
        var displ = this.displaysForProperties.find(d => d.PropertyName == predicate(self));
        if (!displ) {
            displ = new FormProperty();
            displ.PropertyName = predicate(self);
            this.displaysForProperties.push(displ);
        }
        return displ;
    }

    public ForPropertyByName = (propName: string): FormProperty => {
        return this.ForProperty(_self => propName);
    }

    public ValidateProperty = (propName: string, value: any, addErrors: (prop: string, errors: string[]) => AppThunkAction<any>) => {
        var displ = this.displaysForProperties.find(d => d.PropertyName === propName);
        if (!displ) {
            throw "No display for property " + propName;
        }
        var errors = [];

        displ.validations.forEach(v => {
            var err = v(value);
            if (err && err.length != 0) {
                errors.push(err);
            }
        })

        if (errors.length > 0) {
            addErrors(propName, errors);
        }
    }
    public GetDisplayValueForProperty = (propName: string, self: BaseFormEntity): JSX.Element => {
        var disp = self.displaysForProperties.find(d => d.PropertyName === propName);
        if (!disp) {
            throw "No display for property " + propName;
        }
        return disp.DisplayMethod.apply(this, [self, ...disp.Args]);
    };

    public GetDisplayNameForProperty = (propName: string): string => {
        var disp = this.displaysForProperties.find(d => d.PropertyName === propName);
        if (!disp) {
            throw "No display for property " + propName;
        }
        return disp.DisplayName ? disp.DisplayName : null;
    };

    public GetPlaceHolderForProperty = (propName: string): string => {
        var disp = this.displaysForProperties.find(d => d.PropertyName === propName);
        if (!disp) {
            throw "No disp for property " + propName;
        }
        return disp.placeholder ? disp.placeholder : null;
    }

    public GetInputTypeForProperty = (propName: string): EntityFieldInputType => {
        var disp = this.displaysForProperties.find(d => d.PropertyName === propName);
        if (!disp) {
            throw "No disp for property " + propName;
        }
        return disp.inputType ? disp.inputType : this.calculateInputTypeBasedDisplay(disp);
    }

    public GetPropertyTypeForProperty = (propName: string): EntityPropertyTypes => {
        var disp = this.displaysForProperties.find(d => d.PropertyName === propName);
        if (!disp) {
            throw "No disp for property " + propName;
        }
        return disp.propertyType ? disp.propertyType : EntityPropertyTypes.String;
    }

    public GetClassNameForProperty = (propName: string): string => {
        var disp = this.displaysForProperties.find(d => d.PropertyName === propName);
        if (!disp) {
            throw "No disp for property " + propName;
        }
        return disp.className ? disp.className : null;
    }

    public GetSearchByTermPropertyName = (propName: string): string => {
        var disp = this.displaysForProperties.find(d => d.PropertyName === propName);
        if (!disp) {
            throw "No disp for property " + propName;
        }
        return disp.searchByTermPropertyName ? disp.searchByTermPropertyName : null;
    }

    public GetSearchByTermPropertyIdIsGuid = (propName: string): boolean => {
        var disp = this.displaysForProperties.find(d => d.PropertyName === propName);
        if (!disp) {
            throw "No disp for property " + propName;
        }
        return disp.searchByTermPropertyIdIsGuid ? disp.searchByTermPropertyIdIsGuid : null;
    }

    public GetDynamicDropDownDataFunction = (propName: string): (...args: any[]) => Promise<ISelectListItem[]> => {
        var disp = this.displaysForProperties.find(d => d.PropertyName === propName);
        if (!disp) {
            throw "No disp for property " + propName;
        };
        return disp.getDynamicDropDownData ? disp.getDynamicDropDownData : () => { return new Promise<ISelectListItem[]>(null); };
    }

    public GetDynamicDropDownDataArgs = (propName: string): any[] => {
        var disp = this.displaysForProperties.find(d => d.PropertyName === propName);
        if (!disp) {
            throw "No display for property" + propName;
        }

        return disp.getDynamicDropDownDataArgs ? disp.getDynamicDropDownDataArgs : [];
    }

    public GetStaticDropDownData = (propName: string): ISelectListItem[] => {
        var disp = this.displaysForProperties.find(d => d.PropertyName === propName);
        if (!disp) {
            throw "No disp for property " + propName;
        }
        return disp.staticDropDownData ? disp.staticDropDownData : [];

    }

    private RegisterDefaultDisplaysForProperties = (onChange: (propName: string, data: any) => any) => {

        this.TrueProperties.forEach(p => {
            this.ForPropertyByName(p).Show((self: any) => (
                <FormInput
                    value={this.calculateTypeBasedDisplay(p)}
                    onChange={onChange}
                />), this);
        });
    }

    public IsHidden = (propName: string): boolean => {
        var disp = this.displaysForProperties.find(d => d.PropertyName === propName);
        if (!disp) {
            throw "No disp for property " + propName;
        }
        return disp.hidden ? disp.hidden : false;
    }

    public IsReadonly = (propName: string): boolean => {
        var disp = this.displaysForProperties.find(d => d.PropertyName === propName);
        if (!disp) {
            throw "No disp for property " + propName;
        }
        return (disp.readOnly ? disp.readOnly : false) || (disp.readOnlyFunc ? disp.readOnlyFunc() : false);
    }

    public IsIgnored = (propName: string): boolean => {
        var disp = this.displaysForProperties.find(d => d.PropertyName === propName);
        if (!disp) {
            throw "No disp for property" + propName;
        }
        return disp.isIgnored ? disp.isIgnored : false;
    }

    public HasCustomDisplay = (propName: string): boolean => {
        var disp = this.displaysForProperties.find(d => d.PropertyName === propName);
        if (!disp) {
            throw "No disp for property" + propName;
        }
        return disp.isCustomShow;
    }

    private calculateInputTypeBasedDisplay = (obj: any): EntityFieldInputType => {
        switch (this.getConstructorOfObject(obj)) {
            case this.getConstructorOfObject(new Boolean()):
                return EntityFieldInputType.Radio;
        }
        return EntityFieldInputType.Text;
    }

    private calculateTypeBasedDisplay = (obj: any): ITypeBasedDisplay => {
        switch (this.getConstructorOfObject(obj)) {
            case this.getConstructorOfObject(new Date()):
                return (d: Date) => d.toLocaleString("ro-RO");
            case this.getConstructorOfObject(new Boolean()):
                return (b: boolean) => b.toString();
        }

        return (r: any) => r != null && r != "null" ? r.toString() : "";
    }

    private getConstructorOfObject = (obj: any): any => {
        return Object.getPrototypeOf(obj).constructor;
    }
}

export class DisplayProperty {
    PropertyName: string;
    DisplayName: string;
    DisplayMethod: (...args: any[]) => JSX.Element;
    Args: any[];

    public Show = (f: (...args: any[]) => JSX.Element, ...args: any[]): DisplayProperty => { this.DisplayMethod = f; this.Args = args; return this; };
    public SetDisplayName = (name: string): DisplayProperty => { this.DisplayName = name; return this; }
}

export class BaseEntity implements IEntity {
    private displaysForProperties: DisplayProperty[];
    public TrueProperties: string[];

    constructor() {
        this.displaysForProperties = new Array<DisplayProperty>();
    }

    public additionalConstructor(child: BaseEntity, dataSource: any) {
        child.TrueProperties = GetRealPropertiesOfObject(child, new BaseEntity());
        child.RegisterDefaultDisplaysForProperties();
        MapObject(dataSource, child);
    }

    public ForProperty = (predicate: (entity: this) => string): DisplayProperty => {
        var self = this;
        var displ = this.displaysForProperties.find(d => d.PropertyName == predicate(self));
        if (!displ) {
            displ = new DisplayProperty();
            displ.PropertyName = predicate(self);
            this.displaysForProperties.push(displ);
        }
        return displ;
    }

    public ForPropertyByName = (propName: string): DisplayProperty => {
        return this.ForProperty(_self => propName);
    }

    public GetDisplayValueForProperty = (propertyName: string, ...additionalArgs: any[]): JSX.Element => {
        var disp = this.displaysForProperties.find(d => d.PropertyName === propertyName);
        if (!disp) {
            throw "No display for property " + propertyName;
        }
        return additionalArgs.length > 0 ? disp.DisplayMethod.apply(this, disp.Args.concat(...additionalArgs)) : disp.DisplayMethod.apply(this, disp.Args);
    };

    public GetDisplayNameForProperty = (propertyName: string): JSX.Element => {
        var disp = this.displaysForProperties.find(d => d.PropertyName === propertyName);
        if (!disp) {
            throw "No display for property " + propertyName;
        }
        return <span>{disp.DisplayName ? disp.DisplayName : disp.PropertyName}</span>;
    };

    public GetColumnNameForProperty = (propertyName: string): string => {
        var disp = this.displaysForProperties.find(d => d.PropertyName === propertyName);
        if (!disp) {
            throw "No display for prop" + propertyName;
        }
        return disp.DisplayName;
    }

    private RegisterDefaultDisplaysForProperties = () => {
        this.TrueProperties.forEach(p => {
            this.ForPropertyByName(p).Show((self: any) => <span>{this.calculateTypeBasedDisplay(self[p])(self[p])}</span>, this);
        });
    }

    private calculateTypeBasedDisplay = (obj: any): ITypeBasedDisplay => {
        if (obj && obj != null) {
            switch (this.getConstructorOfObject(obj)) {
                case this.getConstructorOfObject(new Date()):
                    return (d: Date) => d.toLocaleString("ro-RO");
                case this.getConstructorOfObject(new Boolean()):
                    return (b: boolean) => b.toString();
            }
        }

        return (r: any) => r != null && r != "null" ? r.toString() : "";
    }

    private getConstructorOfObject = (obj: any): any => {
        return Object.getPrototypeOf(obj).constructor;
    }
}

export enum EntityPropertyTypes {
    String = 1,
    Boolean = 2,
    Date = 3,
    Number = 4,
    Guid = 5
}
export enum EntityFieldInputType {
    Text = "text",
    Radio = "radio",
    Dropdown = "dropwdown",
    Password = "password",
    File = "file",
    Number = "number",
    Checkbox = "checkbox",
    MultipleSelect = "multiple-select",
    Date = "date",
    SearchDropDown = "search-dropdown",
    MultipleSearchDropDown = "multiple-search-dropdown",
    GridForm = "grid-form",
    List = "list",
    Image = "image",
    Link = "link", 
    RichText = "rich-text"
}

export interface ITabTitleItem {
    title: string,
    class?: string,
    tabId: string;
}

export interface ITabItem {
    tabId: string;
    title: string;
    class?: string;
    content: (apgd:any) => React.ReactNode;
}
