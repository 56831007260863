import React from "react";
import { ISpaceActivity } from "../../models/Spaces/Interfaces/ISpaceActivity";
import { MONTH_FORMAT, formatDate } from "../../utils/date";
import Workspaces from "../../resources/AdminUI/Spaces/Workspaces";


interface IProps {
    item: ISpaceActivity;
}

interface IState { }

export default class SpaceMonthActivityCard extends React.PureComponent<IProps, IState>{
    constructor(props) {
        super(props);
    }

    render() {
        return <div className="container_activity_month">
            <div>
                <p className="container_activity_title"> {formatDate(this.props.item.month, MONTH_FORMAT)} </p>
            </div>
            <div className="activity_all_values">
                <div style={{ display: "flex" }}>
                    <div className="activity_history_value">{this.props.item.fullDays}</div>
                    <span className="activity_history_value_description">{Workspaces.Resources.fullDays}</span>
                </div>
                <div style={{ display: "flex" }}>
                    <div className="activity_history_value"> {this.props.item.halfDays}</div>
                    <span className="activity_history_value_description">{Workspaces.Resources.halfDays}</span>
                </div>
                <div style={{ display: "flex" }}>
                    <div className="activity_history_value">{this.props.item.unredeemed}</div>
                    <span className="activity_history_value_description">{Workspaces.Resources.unredeemed}</span>
                </div>
            </div>
        </div>
    }
}

