let response = await fetch('/appsettings.json');
let json = await response.json();

export module Settings {
    export interface IAppSettings {
        CommonSettings: ICommonSettings,
        LanguageSettings: ILanguageSettings,
    } 

    export interface ICommonSettings {
        ApiUrl: string;
        IdpUrl: string;
        UIUrl: string;
        ImageBaseUrl: string;
        SearchDropDownPageSize: number;
        AppVersion: string;
        GoogleMapsSearchUrl: string;
    }

    export interface ILanguageSettings {
        SupportedLanguages: ISupportedLanguage[],
        DefaultLanguage: string       
    }

    export interface ISupportedLanguage {
        Code: string,
        Name: string,
        CultureName: string
    }
}

export const AppSettings = json.AppSettings as Settings.IAppSettings;