import React from "react";
import Helmet from "react-helmet";
import { ApplicationState } from "../../store";
import { withRouter } from "../../components/Shared/withRouter";
import { connect } from "react-redux";
import General from "../../resources/AdminUI/General";
import { WorkspacesStore } from "../../store/WorkspacesStore";
import { ISpaceItem } from "../../models/Spaces/Interfaces/ISpaceItem";
import SpacesDetailsTabs from "../../components/Spaces/SpacesDetailsTabs";
import SpacesTotalCheckinsTabs from "../../components/Spaces/SpacesTotalCheckinsTabs";
import SpacesTotalActivityTabs from "../../components/Spaces/SpacesTotalActivityTabs";
import { ISpaceActivity } from "../../models/Spaces/Interfaces/ISpaceActivity";
import Workspaces from "../../resources/AdminUI/Spaces/Workspaces";
import { String_Format } from "../../utils/utils";
import Loading from "../../components/Shared/Loading";

interface IProps {
  loading?: boolean;
  spaces?: ISpaceItem[];
  statistics: ISpaceActivity[];
  getData?: () => void;
  getSpacesStatistics?: () => void;
}

interface IState {}

class DashboardPage extends React.PureComponent<IProps, IState> {
  constructor(props) {
    super(props);

    this.props.getData();
    this.props.getSpacesStatistics();
  }

  render() {
    if (this.props.loading || !this.props.spaces || !this.props.statistics) {
      return (
        <div>
          <Loading />
        </div>
      );
    }

    return (
      <div className="fullpage_container">
        <Helmet title="Dashboard" />

        <div className="main_container">
          <div className="column_left">
            <div className="widget_container full_height">
              <p className="widget_title">
                {String_Format(
                  Workspaces.Resources.containsWorkspaces,
                  this.props.spaces.length
                )}
              </p>
              <SpacesDetailsTabs
                componentId={`space-details`}
                spaces={this.props.spaces}
              />
            </div>

            <div className="widget_container fixed_height">
              <p className="widget_title">
                {Workspaces.Resources.totalCheckins}
              </p>
              <SpacesTotalCheckinsTabs
                componentId={`space-total-checkins`}
                spaces={this.props.spaces}
              />
            </div>
          </div>

          <div className="column_right">
            <div className="widget_container full_height">
              <p className="widget_title">
                {Workspaces.Resources.totalActivity}
              </p>
              <SpacesTotalActivityTabs
                componentId={`space-total-activity`}
                spaces={this.props.spaces}
                statistics={this.props.statistics}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state: ApplicationState) => {
      return {
        spaces: state.dashboard.spaces,
        loading: state.dashboard.loading,
        statistics: state.dashboard.statistics,
      };
    },
    {
      getData: WorkspacesStore.actionCreators.getData,
      getSpacesStatistics: WorkspacesStore.actionCreators.getSpacesStatistics,
    }
  )(DashboardPage)
);
