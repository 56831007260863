import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import { ISpaceItem } from "../models/Spaces/Interfaces/ISpaceItem";
import update from "immutability-helper";
import WorkspacesService from "../services/WorkspacesService";
import { ISpaceActivity } from "../models/Spaces/Interfaces/ISpaceActivity";

export module WorkspacesStore {

    export interface IState {
        spaces: ISpaceItem[],
        statistics: ISpaceActivity[],

        loading: boolean,

    }

    export enum Actions {
        ToggleLoading = "SPACES_TOGGLE_LOADING",
        Initialize = "SPACES_INITIALIZE",
        SetData = "SPACES_SET_DATA",
        SetStatistics = "SPACES_SET_STATISTICS",
    }

    interface IInitialize {
        type: Actions.Initialize;
    }

    interface IToggleLoading {
        type: Actions.ToggleLoading;
        loadingValue: boolean;
    }

    interface ISetData {
        type: Actions.SetData,
        spaces: ISpaceItem[]
    }

    interface ISetStatistics {
        type: Actions.SetStatistics,
        statistics: ISpaceActivity[]
    }

    type KnownAction = IToggleLoading |
        IInitialize | ISetData | ISetStatistics;

    export const actionCreators = {

        getData: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            dispatch({ type: Actions.Initialize });
            dispatch({ type: Actions.ToggleLoading, loadingValue: true });

            var spaces = await WorkspacesService.getSpaces().then(res => res.value);

            if (spaces.length != 0) {
                dispatch({ type: Actions.SetData, spaces: spaces });
            } else {
                dispatch({ type: Actions.SetData, spaces: [] });
            }

            dispatch({ type: Actions.ToggleLoading, loadingValue: false });
        },
        getSpacesStatistics: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            var statistics = await WorkspacesService.getSpacesStatistics().then(res => res.value);
            if (statistics.length != 0) {
                dispatch({ type: Actions.SetStatistics, statistics: statistics });
            } else {
                dispatch({ type: Actions.SetStatistics, statistics: [] });
            }
        }
    }

    const initialState: IState = {
        loading: false,
        spaces: null,
        statistics: null
    }

    export const reducer: Reducer<IState> = (currentState: IState, incomingAction: Action) => {
        const action = incomingAction as KnownAction;

        switch (action.type) {
            case Actions.Initialize:
                return initialState;
            case Actions.ToggleLoading:
                return { ...currentState, loading: action.loadingValue }
            case Actions.SetData:
                return update(currentState,
                    {
                        spaces: { $set: action.spaces }
                    });
            case Actions.SetStatistics:
                return update(currentState,
                    {
                        statistics: { $set: action.statistics }
                    });
            default:
                return currentState || initialState;
        }


    }

}