import Globals from "./../../../Globals";

export default class HotdeskBookingStatusTypes {
       public allStatuses: string = `Any status`;
       public cancelled: string = `Cancelled`;
       public checkedin: string = `Checked in`;
       public ended: string = `Ended`;
       public scheduled: string = `Scheduled`;
       public unredemeed: string = `Unredemeed`;

		public static get Resources(): HotdeskBookingStatusTypes{
			switch (Globals.currentActiveLanguage) {
				default:
				return new HotdeskBookingStatusTypes();
		}
   	}
}
