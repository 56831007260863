import React from "react";
import { ITabTitleItem } from "../../utils/reactUtils";

interface IProps {
    tabs: ITabTitleItem[],
    onTabClick: (tabTitle: string) => void
    activeTabId?: string,
    className?: string
}

export class TabsHeader extends React.PureComponent<IProps, {}>{
    constructor(props) {
        super(props);
    }
    activeTabClass: string = 'tab-selected';

    render() {
        return <div className={"tab-group " + this.props.className}>
            {this.props.tabs.map(t => {
                return <div key={t.tabId} className={`tab ${t.class ? t.class : ''} ${t.tabId == this.props.activeTabId ? this.activeTabClass : ''}`}
                    onClick={() => { this.props.onTabClick(t.tabId) }}> {t.title} </div>
            })}
        </div>
    }
}