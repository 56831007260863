import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ApplicationState } from "../../store";
import { TabStore } from "../../store/TabStore";
import { TabsHeader } from "../Tabs/TabsHeader";
import { ITabItem, ITabTitleItem } from "../../utils/reactUtils";
import General from "../../resources/AdminUI/General";
import SpaceDetailsCard from "./SpaceDetailsCard";
import Loading from "../Shared/Loading";

class SpacesDetailsTabs extends React.PureComponent<any, any> {
  constructor(props) {
    super(props);

    if (this.props.spaces.length != 0) {
      var tabs = this.props.spaces.map((e) => {
        return {
          tabId: e.id,
          title: e.name,
          class: "",
          content: () => (
            <SpaceDetailsCard
              key={e.id}
              name={e.name}
              categoryName={e.categoryName}
              address={e.address}
              countryName={e.countryName}
              cityName={e.cityName}
              maxSeats={e.maxSeats}
              meetingRooms={e.meetingRooms}
              privateOffices={e.privateOffices}
              image={e.image}
            />
          ),
        } as ITabItem;
      });

      this.props.initializeTabPage(tabs, tabs[0].tabId, null);
    }
  }

  render() {
    {
      if (this.props.tabState == undefined) {
        return (
          <div>
            <Loading />
          </div>
        );
      }
    }
    var activeTab = this.props.tabState.tabs.filter(
      (t) => t.tabId == this.props.tabState.activeTabId
    )[0];

    return (
      <div>
        <TabsHeader
          onTabClick={this.props.changeActiveTab}
          activeTabId={activeTab.tabId}
          className={"section-table-tabs"}
          tabs={this.props.tabState.tabs.map((t) => {
            return {
              title: t.title,
              tabId: t.tabId,
              class: t.class,
            } as ITabTitleItem;
          })}
        />
        <div>{activeTab.content()}</div>
      </div>
    );
  }
}

export default connect(
  (state: ApplicationState, ownProps: any) => {
    return { tabState: state.tab[ownProps.componentId] };
  },
  (dispatch, ownProps: any) =>
    bindActionCreators(
      {
        ...TabStore.getActionCreators(ownProps.componentId),
      },
      dispatch
    )
)(SpacesDetailsTabs);
