import { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../store";
import { ApplicationPaths } from "../enums/Common/ApplicationPaths";
import { NavLink } from "react-router-dom";
import { getPromiseFromAction } from "../utils/utils";
import RoomBookingsService from "../services/RoomBookingsService";
import MenuItems from "../resources/AdminUI/MenuItems";
import { LoginStore } from "../store/LoginStore";
import { WorkspaceGroupStore } from "../store/WorkspaceGroupStore";
import { MenuOutlined, CloseOutlined } from "@ant-design/icons";

interface IProps {
  loading?: boolean;
  logout?: () => void;
  workspaceGroup?: IWorkspaceGroup;
  getData?: () => void;
}

const Nav: React.FC<IProps> = ({ logout, workspaceGroup }) => {
  const [arePendingRoomRequests, setArePendingRoomRequests] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const timerRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    timerRef.current = setInterval(() => {
      getPromiseFromAction(
        RoomBookingsService.getNumberOfPendingRoomRequests() as any
      ).then((res) => {
        const hasPendingRequests = !res || (res as any).value > 0;
        setArePendingRoomRequests(hasPendingRequests);
      });
    }, 60000);

    return () => {
      clearInterval(timerRef.current);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <div>
      <div className="mobile-menu-icon" onClick={toggleMenu}>
        <MenuOutlined />
      </div>
      <div className={`overlay ${isMenuOpen ? "open" : ""}`} />
      <section className={`nav-container ${isMenuOpen ? "open" : ""}`}>
        <div className="close-menu-icon" onClick={toggleMenu}>
          <CloseOutlined />
        </div>
        <div className="logo-container">
          <img
            src="/assets/images/logo_app.svg"
            className="logo"
            alt="App Logo"
          />
        </div>

        <div className="account-info">
          <div className="initials">
            {workspaceGroup?.name
              .split(" ")
              .map((word) => word.charAt(0))
              .slice(0, 2)
              .join("")}
          </div>
          <div>
            <div className="dashboard-user">{workspaceGroup?.name}</div>
            <div className="dashboard-name">
              {MenuItems.Resources.spaceAdmin}
            </div>
          </div>
        </div>

        <div className="menu">
          <div className="menu-section">
            <div className="menu-section-title">
              {MenuItems.Resources.general}
            </div>
            <NavLink
              className={({ isActive }) =>
                isActive ? "active menu-item-container" : "menu-item-container"
              }
              to={ApplicationPaths.Dashboard}
              onClick={closeMenu}
            >
              <div className="material-symbols-outlined notranslate">
                corporate_fare
              </div>
              <div className="title">{MenuItems.Resources.dashboard}</div>
            </NavLink>
          </div>

          <div className="menu-section">
            <div className="menu-section-title">
              {MenuItems.Resources.activity}
            </div>
            <NavLink
              className={({ isActive }) =>
                isActive ? "active menu-item-container" : "menu-item-container"
              }
              to={ApplicationPaths.DesksLogs}
              onClick={closeMenu}
            >
              <div className="material-symbols-outlined notranslate">
                calendar_month
              </div>
              <div className="title">{MenuItems.Resources.desksLogs}</div>
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive ? "active menu-item-container" : "menu-item-container"
              }
              to={ApplicationPaths.RoomsLogs}
              onClick={closeMenu}
            >
              <div className="material-symbols-outlined notranslate">
                calendar_month
              </div>
              <div className="title">{MenuItems.Resources.roomsLogs}</div>
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive ? "active menu-item-container" : "menu-item-container"
              }
              to={ApplicationPaths.PendingRoomRequests}
              onClick={closeMenu}
            >
              <div className="material-symbols-outlined notranslate">
                hourglass_bottom
              </div>
              <div className="title">{MenuItems.Resources.pendingRequests}</div>
            </NavLink>
          </div>

          <div className="menu-section">
            <div className="menu-section-title">
              {MenuItems.Resources.settings}
            </div>
            <NavLink
              className="menu-item-container"
              to={ApplicationPaths.EmptyPath}
              onClick={logout}
            >
              <div className="material-symbols-outlined notranslate">
                logout
              </div>
              <div className="title">{MenuItems.Resources.logout}</div>
            </NavLink>
          </div>
        </div>
      </section>
    </div>
  );
};

export default connect(
  (state: ApplicationState) => ({
    workspaceGroup: state.workspaceGroup,
  }),
  {
    logout: LoginStore.actionCreators.logout,
    getData: WorkspaceGroupStore.actionCreators.getData,
  }
)(Nav);
