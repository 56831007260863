import { useNavigate, useSearchParams } from 'react-router-dom';

export const withRouter = (Component) => {
  const Wrapper = (props) => {
    const navigate = useNavigate();
    const [queryArgs, _] = useSearchParams();
    
    return (
      <Component
        navigate={navigate}
        params={queryArgs}
        {...props}
        />
    );
  };
  
  return Wrapper;
};