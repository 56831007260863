import Globals from "./../../../Globals";

export default class RoomBookingTypeList {
       public daily: string = `Daily`;
       public hourly: string = `Hourly`;
       public monthly: string = `Monthly`;

		public static get Resources(): RoomBookingTypeList{
			switch (Globals.currentActiveLanguage) {
				default:
				return new RoomBookingTypeList();
		}
   	}
}
